.vacancyCard {
  &__main-info-text {
    margin-top: 0;
  }
  &__left-container {
    margin: 10px 0 8px;
  }
  &__left {
    max-width: 300px;
    width: 100%;
    display: flex;
    gap: 8px;
    margin-top: 8px;
    & button {
      @include text('Noto Sans', normal, 600, 15px, 20px);
      border-radius: 8px;
      outline: none;
      border: none;
      padding: 10px;
      cursor: pointer;
    }
  }
  &__btn {
    &-posting {
      width: 126px;
      background-color: $orange;
      color: #fff;
    }
    &-upgrade {
      width: 156px;
      background: $darkBlue;
      color: $orange;
      & span {
        position: relative;
        &::before {
          content: '';
          width: 36px;
          height: 36px;
          position: absolute;
          left: -36px;
          top: 0;
          background: url('../../../../assets/svg/txtStarLeft.svg');
        }
        &::after {
          content: '';
          width: 36px;
          height: 36px;
          position: absolute;
          right: -36px;
          top: 0;
          background: url('../../../../assets/svg/txtStarRight.svg');
        }
      }
    }
  }
  &__select {
    @include text('Roboto', normal, 400, 15px, 18px);
    color: $black;
  }
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__footer-left {
    display: flex;
    align-items: center;
    gap: 20px;
    & .action {
      color: $orange;
      cursor: pointer;
    }
    &.second {
      margin-top: 6px;
      font-weight: 500;
      color: #003865;
    }
    &.third {
      margin-top: 6px;
      font-weight: 500;
      color: #d71f4b;
    }
  }
  &__handlers {
    @include text('Noto Sans', normal, 400, 14px, 15px);
    color: #003865;
    max-width: 200px;
    & ul {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      justify-content: center;
      text-decoration: underline;
      & li {
        position: relative;
        cursor: pointer;
        &::before {
          content: '';
          width: 100%;
          height: 1px;
          background-color: #003865;
          bottom: 0;
          position: absolute;
        }
        &:last-child {
          color: #d71f4b;
        }
        &:last-child {
          &::before {
            background-color: #d71f4b;
          }
        }
      }
    }
  }
}
