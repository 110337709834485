.employers {
  position: relative;
  &__title {
    @include text('Noto Sans', normal, 600, 20px, 27px);
    color: $black;
    text-align: center;
  }
  &__cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    flex-wrap: wrap;
    justify-content: center;
    gap: 17px;
    margin: 24px 0 32px;
    @media (max-width: 961px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    @media (max-width: 760px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @media (max-width: 520px) {
      grid-template-columns: 1fr 1fr;
    }
    &--list {
      @media (max-width: 390px) {
        grid-template-columns: 1fr;
      }
      & .employers__cards-card {
        margin: 0 auto;
      }
    }
    &-card {
      padding-inline: 10px;
      overflow: hidden;
      position: relative;
      display: flex;
      max-width: 252px;
      max-height: 112px;
      width: 100%;
      height: 112px;
      border-radius: 12px;
      box-shadow: (0px 2px 6px rgba(0, 0, 0, 0.1));
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background: #fff;
      & img {
        -o-object-fit: contain;
        object-fit: contain;
        max-height: 80%;
        max-width: 100%;
      }
      & h5 {
        @include text('Noto Sans', normal, bold, 16px, 23px);
        width: 100%;
        text-align: center;
        padding: 0 10px;
        color: $black;
        text-transform: capitalize;
      }
      &-hover {
        padding: 16px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        background: rgba(7, 7, 7, 0.5);
        color: #fff;
        transform: translateY(70%);
        transition: all 0.3s;
        opacity: 0;
        &.dark {
          background: rgba(7, 7, 7, 0.9);
        }
        & .h4,
        & h4 {
          @include text('Noto Sans', normal, 600, 17px, 23px);
        }
        & p {
          @include text('Noto Sans', normal, 500, 16px, 22px);
        }
      }
      &:hover {
        & img {
          -webkit-filter: blur(50%);
          filter: blur(50%);
          transform: 0.3s;
        }
      }
      &:hover &-hover {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  &__nav {
    display: flex;
    gap: 5px;
    position: absolute;
    bottom: calc(100% - 34px);
    right: 0;
    & div {
      cursor: pointer;
      color: $gray;
      & svg {
        width: 25px;
        height: 30px;
      }
    }
  }
}
