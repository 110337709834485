#root {
  transition: all 0.3s ease-in-out;
  margin: 0;
  min-height: calc(100vh - 20px);
  padding: 0;
  display: flex;
  flex-direction: column;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: 10px;
  scrollbar-color: #b4bbc0 transparent;
}
/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  border-radius: 8px;
  width: 10px;
}
*::-webkit-scrollbar-track {
  border-radius: 8px;
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #b4bbc0;
  border-radius: 15px;
  border: -35px solid transparent;
}

$black: #070707;
$lightBlack: #070707b3;
$gray: #919191;
$orange: #ffb310;
$hoverOrange: #d69711;
$darkBlue: #003865;

body {
  background: #f9f9f9;
  -ms-scroll-chaining: none;
  overscroll-behavior: none;
}

main {
  flex: 1;
}
footer {
  flex: 0;
}
.container {
  width: 100%;
  max-width: 1300px;
  padding: 0 15px;
  margin: 0 auto;
}
.popup {
  transition: unset;
  width: 100vw;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  &__bg {
    transition: unset;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
  }
  &__content {
    transition: unset;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__close {
    transition: unset;
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }
}
hr {
  color: rgba(7, 7, 7, 0.1);
}
.css-b62m3t-container {
  white-space: nowrap;
  & .css-2513qy-menu {
    box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.25);
    border-radius: 12px !important;
  }
  & .css-1s2u09g-control {
    border-radius: 8px;
  }
  & .css-1okebmr-indicatorSeparator {
    display: none;
  }
}
.rmsc .dropdown-container {
  border-radius: 8px !important;
  & .options {
    padding: 8px 0;
    & .select-item {
      position: relative;
      font-family: 'Noto Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      padding: 6px 23px;
      & input[type='checkbox'] {
        opacity: 0;
      }
      &::before {
        content: '';
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
        width: 18px;
        height: 18px;
        border: 1px solid rgba(7, 7, 7, 0.35);
        background-color: #fff;
        border-radius: 4px;
      }
      &.selected {
        background-color: #fff;
      }
      &.selected::before {
        background: $orange url('../../assets/svg/check.svg') center center
          no-repeat;
        border: $orange;
      }
    }
  }
}

.horizontal-scroll {
  position: relative;
  max-width: 388px;
  &__container {
    overflow-x: hidden;
    width: 100%;
  }
  &__btn {
    width: 30px;
    height: 30px;
    display: grid;
    place-items: center;
    position: absolute;
    border-radius: 50%;
    background-color: #f9f9f9;
    border: none;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 0.3s ease-out;
    cursor: pointer;
    z-index: 1;
    &.hidden {
      display: none;
    }
    &:hover {
      background: #e9e9ed;
    }
    &--left {
      left: -10px;
      box-shadow: 5px 0px 5px 3px rgba(255, 255, 255, 1);
    }
    &--right {
      right: -10px;
      box-shadow: -5px 0px 5px 3px rgba(255, 255, 255, 1);
    }
  }
}

.css-183a8p-option {
  white-space: break-spaces;
}

.unSelect {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:focus {
    outline: none !important;
  }
}

.fancybox__image {
  background: #fff;
}

.fancybox__caption {
  @include text('Noto Sans', normal, 500, 20px, 21px);
}
