.cart {
  max-width: 536px;
  margin: 0 auto;
  &__top {
    position: relative;
    display: flex;
    justify-content: center;
    &-title {
      @include text('Noto Sans', normal, 600, 20px, 27px);
      text-align: center;
      margin: 16px 0;
    }
  }
  &-title {
    @include text('Noto Sans', normal, 500, 16px, 22px);
    color: $darkBlue;
    margin-bottom: 8px;
  }
  &__content {
    background: #ffffff;
    box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.18);
    border-radius: 10px;
    padding: 20px 24px;
    &-empty {
      @include text('Noto Sans', normal, 500, 16px, 22px);
      text-align: center;
    }
    &-emptyLink {
      text-align: center;
      & a {
        @include text('Noto Sans', normal, 500, 16px, 22px);
        color: $black;
        text-decoration: underline;
      }
    }
    &-success {
      & .checkmark {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        display: block;
        stroke-width: 2;
        stroke: #4bb71b;
        stroke-miterlimit: 10;
        box-shadow: inset 0px 0px 0px #4bb71b;
        -webkit-animation: fill 0.4s ease-in-out 0.4s forwards,
          scale 0.3s ease-in-out 0.9s both;
        animation: fill 0.4s ease-in-out 0.4s forwards,
          scale 0.3s ease-in-out 0.9s both;
        position: relative;
        top: 5px;
        right: 5px;
        margin: 0 auto;
        &__circle {
          stroke-dasharray: 166;
          stroke-dashoffset: 166;
          stroke-width: 2;
          stroke-miterlimit: 10;
          stroke: #4bb71b;
          fill: #fff;
          -webkit-animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
          animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
        }
        &__check {
          transform-origin: 50% 50%;
          stroke-dasharray: 48;
          stroke-dashoffset: 48;
          -webkit-animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s
            forwards;
          animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
        }
      }
      &-title {
        text-align: center;
        margin-top: 25px;
        @include text('Roboto', normal, 500, 23px, 21px);
        color: #23a734;
      }
      @-webkit-keyframes stroke {
        100% {
          stroke-dashoffset: 0;
        }
      }
      @keyframes stroke {
        100% {
          stroke-dashoffset: 0;
        }
      }
      @-webkit-keyframes scale {
        0%,
        100% {
          transform: none;
        }

        50% {
          transform: scale3d(1.1, 1.1, 1);
        }
      }
      @keyframes scale {
        0%,
        100% {
          transform: none;
        }
        50% {
          transform: scale3d(1.1, 1.1, 1);
        }
      }
      @-webkit-keyframes fill {
        100% {
          box-shadow: inset 0px 0px 0px 30px #4bb71b;
        }
      }
      @keyframes fill {
        100% {
          box-shadow: inset 0px 0px 0px 30px #4bb71b;
        }
      }
    }
  }
  &-item {
    padding: 16px 0;
    display: flex;
    align-items: center;
    gap: 16px;
    position: relative;
    &-logo {
      width: 66px;
      min-width: 66px;
      height: 66px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 56, 101, 0.15);
      border-radius: 6px;
    }
    &-text {
      display: flex;
      flex-direction: column;
      gap: 6px;
    }
    &-name {
      white-space: nowrap;
      @include text('Noto Sans', normal, 600, 16px, 16px);
      color: $black;
    }
    &-info {
      white-space: nowrap;
      @include text('Noto Sans', normal, 400, 15px, 20px);
      gap: 12px;
      & p {
        white-space: break-spaces;
      }
      & span {
        color: $black;
      }
    }
    &-price {
      @include text('Noto Sans', normal, 600, 16px, 22px);
      color: $orange;
    }
    &-remove {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
  &-result {
    margin: 16px 0 20px;
    & span:first-child {
      @include text('Noto Sans', normal, 600, 18px, 25px);
      color: $darkBlue;
    }
    & span:last-child {
      @include text('Noto Sans', normal, 600, 20px, 27px);
      color: $orange;
    }
  }
  &__line {
    content: '';
    width: 100%;
    background-color: rgba(0, 56, 101, 0.1);
    height: 1px;
    margin-bottom: 24px;
  }
  & .replenish__subtitle {
    text-align: start;
    font-size: 16px;
    margin-bottom: 14px;
  }
}

.payment {
  padding: 24px 0 32px;
  & img {
    max-width: 50%;
  }
  &__title {
    @include text('Noto Sans', normal, 600, 18px, 25px);
    text-align: center;
  }
  &__info {
    text-align: center;
    margin: 16px 0;
    &-desc {
      @include text('Noto Sans', normal, 400, 15px, 20px);
    }
    &-manager {
      text-align: center;
      &__socs {
        display: flex;
        flex-direction: column;
        gap: 15px;
        ul {
          flex-direction: row;
        }
      }
    }
    & input {
      margin-top: 8px;
      text-align: center;
      width: 150px;
      padding: 6px 12px;
      border: 1px solid rgba(7, 7, 7, 0.2);
      border-radius: 6px;
      @include text('Noto Sans', normal, 600, 18px, 25px);
    }
  }
  &__steps {
    @include text('Noto Sans', normal, 500, 16px, 22px);
    padding-left: 18px;
    &-item {
      list-style: unset;
      list-style-type: unset;
      text-align: center;
      &.large {
        margin-bottom: 34px;
      }
      & p {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        text-align: start;
        & span {
          color: $orange;
        }
      }
      & img {
        margin: 10px auto 24px;
      }
    }
  }
  &__btn {
    background: $orange;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    border: 0;
    width: 100%;
    text-align: center;
    padding: 14px;
    @include text('Noto Sans', normal, 800, 15px, 16px);
    color: #fff;
    cursor: pointer;
    &--replenish {
      background: #003865;
      margin-bottom: 15px;
    }
    &:disabled {
      background: #ced3d9;
    }
    &--redirected {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #4bb71b;
    }
  }
  &__form {
    margin: 18px 0 0 -9px;
    & .crud__template-wrapper {
      margin: 0 auto;
      & span {
        text-align: start;
      }
    }
  }
}
