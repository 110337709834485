.pagination {
  margin-top: 16px;
  float: right;
  max-height: 28px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 8px;
  &__pageValue {
    @include text('Noto Sans', normal, 400, 14px, 19px);
    padding: 4px 0;
    text-align: center;
    max-width: 56px;
    border: 1px solid rgba(7, 7, 7, 0.3);
    border-radius: 6px;
  }
  &__pages {
    @include text('Noto Sans', normal, 400, 14px, 19px);
    color: $gray;
  }
  & button {
    display: flex;
    align-items: center;
    padding: 4px 10px;
    outline: none;
    background: $darkBlue;
    color: #fff;
    cursor: pointer;
    border: none;
    border-radius: 6px;
  }
}
