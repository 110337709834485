.allResVac {
  width: 100%;
  &.searching {
    padding-bottom: 160px;
  }
  &__title {
    margin: 24px auto;
    text-align: center;
    @include text('Noto Sans', normal, 500, 20px, 27px);
  }
}
