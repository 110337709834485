.draftsList {
  &__content {
    width: 100%;
  }
}

.draftCard {
  width: 100%;
  padding-bottom: 80px;
  &__main-info-types {
    & .draft {
      color: #d71f4b;
      background: rgba(215, 31, 75, 0.2);
    }
  }
  &__description {
    @include text('Noto Sans', normal, 400, 14px, 16px);
    width: 100%;
    max-width: unset;
    margin: 16px 0 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    & p {
      &:first-child {
        font-weight: 600;
        font-size: 16px;
        color: #d71f4b;
      }
    }
  }
}
