.about {
  &__header {
    padding: 44px 20px;
    background: #04062b url('../../../assets/images/searchBg.png') center/cover
      no-repeat;
    height: 320px;
    border-radius: 16px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    &-title {
      & svg {
        width: 100%;
        height: 30px;
      }
      margin-bottom: 12px;
    }
    &-subtitle {
      @include text('Noto Sans', normal, 700, 20px, 27px);
      color: #fff;
    }
  }
  &__content {
    text-align: center;
    max-width: 1008px;
    margin: 0 auto;
    &-contacts {
      scroll-margin-top: 30px;
    }
    &-title {
      @include text('Noto Sans', normal, 600, 22px, 30px);
      margin: 56px auto 24px;
    }
    &-text {
      @include text('Noto Sans', normal, 400, 16px, 20px);
    }
    &-wrapper {
      display: flex;
      gap: 16px;
      flex-wrap: wrap;
      justify-content: center;
    }
    &-map {
      margin-top: 10px;
      & iframe {
        border: 0;
      }
    }
    &-card {
      min-width: 260px;
      overflow: hidden;
      width: calc(50% - 8px);
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      &-top {
        padding: 30px 10px 24px;
        text-align: center;
        & img {
          -o-object-fit: cover;
          object-fit: cover;
          -o-object-position: center;
          object-position: center;
          width: 80px;
          height: 80px;
          border-radius: 50%;
        }
        & h6 {
          margin: 8px 0;
          @include text('Noto Sans', normal, 600, 18px, 25px);
        }
        & p {
          @include text('Noto Sans', normal, 400, 14px, 19px);
          color: $darkBlue;
        }
      }
      &-bottom {
        background: #003865;
        padding: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 16px;
        & a {
          @include text('Noto Sans', normal, 400, 15px, 20px);
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }
}
