.orderService {
  &__list {
    position: absolute;
    transform: translateY(-9px);
    bottom: 100%;
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 8px;
    &.static {
      flex-wrap: wrap;
      position: static;
      margin-top: 20px;
    }
    &-item {
      white-space: nowrap;
      cursor: pointer;
      min-width: 172px;
      padding: 10px 16px;
      display: flex;
      place-items: center;
      text-align: center;
      background: $darkBlue;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.17);
      border-radius: 8px;
      @include text('Noto Sans', normal, 500, 16px, 22px);
      color: #fff;
      &.active {
        color: $orange;
      }
    }
  }
  &__wrapper {
    display: flex;
    gap: 16px;
    justify-content: center;
    &.flex {
      flex-wrap: wrap;
    }
  }
  &__card {
    position: relative;
    width: calc(33.333% - 16px);
    padding: 20px 24px;
    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    &.min {
      min-width: 300px;
    }
    &-title {
      @include text('Noto Sans', normal, 500, 18px, 21px);
      margin-bottom: 7px;
    }
    &-description {
      @include text('Noto Sans', normal, 400, 15px, 16px);
      margin: 7px 0 22px;
    }
    &-select,
    &-price,
    &-input {
      margin-bottom: 10px;
      border-radius: 6px;
    }
    &-input {
      @include text('Noto Sans', normal, 400, 14px, 16px);
      width: 100%;
      border: 1px solid rgba(7, 7, 7, 0.2);
      padding: 8px 16px;
    }
    &-select {
      @include text('Noto Sans', normal, 400, 14px, 16px);
    }
    &-price {
      padding: 8px 16px;
      @include text('Noto Sans', normal, 600, 20px, 23px);
      border: 1px solid $orange;
      color: $orange;
    }
    &-btn {
      @include text('Noto Sans', normal, 800, 15px, 20px);
      color: #fff;
      width: 100%;
      border: none;
      padding: 12px 16px;
      text-align: center;
      background: $orange;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.17);
      border-radius: 8px;
      cursor: pointer;
      &:hover {
        box-shadow: 0px 2px 16px rgba(255, 179, 16, 0.5);
      }
    }
  }
}
