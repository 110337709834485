.postings {
  &__nav {
    max-width: 1008px;
    margin: 16px auto;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    &-handlers {
      display: flex;
      gap: 10px;
      align-items: center;
    }
    &-tag {
      cursor: pointer;
      font-size: 24px;
      width: 24px;
      height: 24px;
      color: #003865;
    }
    &-search {
      & span:last-child {
        background: rgba(0, 56, 101, 0.06);
        border-radius: 10px;
        max-width: 464px;
        min-width: 200px;
        width: 100%;
        padding: 5px 20px;
        display: flex;
        align-items: center;
      }
      & input {
        margin-left: 12px;
        border: none;
        outline: none;
        background: transparent;
        @include text('Noto Sans', normal, 400, 14px, 19px);
      }
    }
    &-list {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      display: flex;
      align-items: center;
      gap: 16px;
      &.custom {
        padding: 10px 0;
      }
      &.overflow {
        padding-inline: 10px;
      }
      & > li {
        cursor: pointer;
        color: #070707;
        padding: 8px 0;
        white-space: nowrap;
        @include text('Noto Sans', normal, 600, 14px, 19px);
        &::before {
          content: '';
          position: absolute;
          height: 2px;
          width: 0;
          bottom: 0;
          background: $orange;
        }
        &.active {
          color: $orange;
        }
        &.active,
        &:hover {
          position: relative;
          &::before {
            width: 100%;
          }
        }
      }
    }
  }
  &__form {
    max-width: 475px;
    & label {
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      display: block;
      position: relative;
      position: relative;
      & svg {
        position: absolute;
        top: 20px;
        left: 18px;
      }
      & textarea {
        @include text('Noto Sans', normal, 400, 15px, 20px);
        color: #070707;
        width: 100%;
        margin: 12px 0 18px;
        border: 1px solid rgba(7, 7, 7, 0.3);
        border-radius: 8px;
        padding: 10px 16px;
      }
      & button {
        position: absolute;
        right: 10px;
        bottom: 30px;
        @include text('Noto Sans', normal, 800, 15px, 20px);
        padding: 12px 32px;
        background: $darkBlue;
        color: #fff;
        outline: none;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.17);
        border-radius: 8px;
        border: none;
        cursor: pointer;
        &:disabled {
          background: #ced3d9;
        }
      }
    }
  }
  &__tag-form {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    max-width: 392px;
    padding: 32px;
    min-width: 360px;
    & h4 {
      @include text('Noto Sans', normal, 600, 22px, 27px);
      margin-bottom: 15px;
    }
    & form {
      display: flex;
      flex-direction: column;
      gap: 15px;
      & input {
        border: 1px solid rgba(7, 7, 7, 0.3);
        border-radius: 8px;
        padding: 10px 12px;
        outline: none;
      }
      & button {
        @include text('Noto Sans', normal, 800, 15px, 20px);
        border-radius: 8px;
        padding: 10px 12px;
        border: none;
        background: #003865;
        color: #fff;
        outline: none;
        cursor: pointer;
      }
    }
  }
  &__tag-context {
    position: fixed;
    overflow: hidden;
    background: #ffffff;
    border: 1px solid rgba(0, 56, 101, 0.1);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    z-index: 1;
    & ul {
      & li {
        cursor: pointer;
        white-space: nowrap;
        @include text('Noto Sans', normal, 500, 15px, 20px);
        padding: 5px 10px;
        text-decoration: none;
        text-align: center;
        &.delete {
          color: #d71f4b;
        }
        &:hover {
          background-color: rgba(0, 56, 101, 0.1);
        }
      }
    }
  }
  &__info {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    &-part {
      display: flex;
      gap: 16px;
      @include text('Roboto', normal, 400, 15px, 18px);
      & span {
        color: $darkBlue;
        text-transform: capitalize;
      }
      & a {
        color: $darkBlue;
        font-weight: 600;
      }
    }
  }
  &__handlers {
    &-denied {
      background: transparent !important;
      color: #070707 !important;
      border: 1px solid #000000 !important;
    }
    & button {
      &:disabled {
        opacity: 0.7;
        border: none;
      }
    }
    &-select {
      @include text('Roboto', normal, 400, 15px!important, 18px!important);
      & > .select {
        &__control {
          border-radius: 8px;
          border: 1px solid #000000 !important;
          min-width: 200px;
          max-width: 320px;
        }
      }
    }
  }
}
