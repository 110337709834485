.main_admin * {
    font-family: "Noto Sans"
}
.btn_orange{
    @include text('Noto Sans', normal, 800, 15px, 20px);
    padding: 12px 32px;
    background: $orange;
    color: #fff;
    outline: none;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.17);
    border-radius: 8px;
    border: none;
    cursor: pointer;

}
.btn_editcomp{
    display: flex;
    justify-content: flex-start;
}
.wrap_input{
    label{
        color: rgba(7, 7, 7, 0.856);
    }
    input{
        @include text('Noto Sans', normal, 800, 15px, 20px);
        width: 100%;
        height: 35px;
        border-radius: 8px;
        border: 1px solid rgba(7, 7, 7, 0.3);
        margin-top: 10px;
    }

}
.wrap_textarea{
    margin-top: 15px;
    label{
        color: rgba(7, 7, 7, 0.856);
    }
    textarea{
        @include text('Noto Sans', normal, 800, 15px, 20px);
        border-radius: 8px;
        border: 1px solid rgba(7, 7, 7, 0.3);
        margin-top: 10px;
    }

}
.center_header_admin{
    display: flex;
    gap: 20px;
    width: 90%;
    margin: 0 160px;
}
.center_header_admin1{
    display: flex;
    gap: 20px;
    width: 90%;
    margin: 80px  0px 0 160px;
}
.main_admin{
    margin: 50px 0 0 0 ;
}