.footer {
  text-align: center;
  padding: 52px 20px 16px;
  background: $black;
  margin-bottom: -20px;
  margin-top: 50px;
  &__wrapper {
    max-width: 672px;
    display: flex;
    gap: 64px;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
  }
  &__list {
    text-align: start;
    display: flex;
    gap: 16px;
    flex-direction: column;
    @media (max-width: 702px) {
      text-align: center;
    }
    & li {
      & h4 {
        @include text('Noto Sans', normal, 600, 16px, 22px);
        color: $orange;
      }
      & a {
        @include text('Noto Sans', normal, 400, 15px, 20px);
        color: #fff;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &-contact {
      text-decoration: none;
      padding: 10px 34px;
      background-color: transparent;
      @include text('Noto Sans', normal, 600, 15px, 20px);
      color: #fff;
      border: 1px solid rgba(255, 255, 255, 0.8);
      -webkit-filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.17));
      filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.17));
      border-radius: 8px;
      cursor: pointer;
      &:hover {
        background-color: #fff;
        color: $black;
      }
    }
  }
  &__copyright,
  &__copyright a {
    margin-top: 52px;
    @include text('Noto Sans', normal, 400, 14px, 19px);
    color: rgba(255, 255, 255, 0.5);
    & a:hover {
      color: #fff;
      text-decoration: underline;
    }
  }
}
