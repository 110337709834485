.viewResumes {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  &__table {
    @include text('Noto Sans', normal, 400, 15px, 24px);
    border-collapse: collapse;
    width: 100%;
    &-title,
    & th,
    & td {
      text-align: start;
      height: 100%;
      vertical-align: middle;
      padding: 14px 16px;
      & a {
        color: $black;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &-title {
      & div {
        gap: 20px;
        flex-wrap: wrap;
        display: flex;
        justify-content: space-between;
        align-items: center;
        & span:last-child {
          max-width: 464px;
          min-width: 200px;
          width: 100%;
          padding: 5px 20px;
          border: 1px solid rgba(7, 7, 7, 0.18);
          border-radius: 4px;
          display: flex;
          align-items: center;
        }
        & input {
          margin-left: 12px;
          border: none;
          outline: none;
          @include text('Noto Sans', normal, 400, 14px, 19px);
        }
      }
    }
    & td {
      &.logos {
        align-items: center;
        gap: 8px;
        display: flex;
        max-width: 304px;
      }
      & img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }
    }
    & tr {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
    & thead {
      background: rgba(0, 56, 101, 0.15);
      & tr {
        border-bottom: none;
        & th {
          font-weight: normal !important;
        }
      }
    }
    & td {
      & span {
        display: inline-block;
        font-weight: 500;
        padding: 2px 8px;
        border-radius: 4px;
        line-height: 24px;
        &.new,
        &.taken {
          color: $orange;
          background: rgba(255, 179, 16, 0.1);
        }
        &.viewed {
          color: rgba(7, 7, 7, 0.52);
          background: rgba(7, 7, 7, 0.06);
        }
        &.rejection {
          color: #d71f4b;
          background: rgba(215, 31, 75, 0.1);
        }
        &.invitation {
          color: #23a734;
          background: #23a7341a;
        }
        &.message {
          cursor: pointer;
          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }
  &__detail {
    position: fixed;
    width: 100%;
    padding: 40px 10px 44px;
    left: 0;
    right: 0;
    background: #ffffff;
    box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.1);
    border-radius: 32px 32px 0px 0px;
    z-index: 2147483649;
    bottom: 0;
    &.mobile {
      padding-bottom: 90px;
    }
  }
}
