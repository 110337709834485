.resumeCard {
  &__main-info-text {
    margin-top: 0;
  }
  &__left {
    &-select {
      min-width: 260px;
      border-radius: 8px;
      @include text('Noto Sans', normal, 400, 15px, 20px);
      margin: 10px 0;
    }

    & button {
      @include text('Noto Sans', normal, 600, 15px, 20px);
      border-radius: 8px;
      outline: none;
      border: none;
      width: 100%;
      padding: 10px;
      background: $darkBlue;
      color: $orange;
      cursor: pointer;
      & span {
        position: relative;
        &::before {
          content: '';
          width: 36px;
          height: 36px;
          position: absolute;
          left: -36px;
          top: 0;
          background: url('../../../../assets/svg/txtStarLeft.svg');
        }
        &::after {
          content: '';
          width: 36px;
          height: 36px;
          position: absolute;
          right: -36px;
          top: 0;
          background: url('../../../../assets/svg/txtStarRight.svg');
        }
      }
    }
    &-container {
      max-width: 268px;
    }
  }
  &__main-logo {
    width: 100px;
    height: 100px;
  }
  &__wrapper {
    gap: 20px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
  &__footer {
    margin-top: 15px;
    z-index: 1 !important;
    &-left {
      &.first {
        @include text('Noto Sans', normal, 400, 13px, 15px);
        max-width: 104px;
      }
      &.last {
        cursor: pointer;
        @include text('Noto Sans', normal, 500, 15px, 20px);
        color: $orange;
        max-width: 120px;
      }
    }
  }
  &__handlers {
    @include text('Noto Sans', normal, 400, 14px, 15px);
    color: #003865;
    max-width: 370px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    &-comment {
      @include text('Noto Sans', normal, 500, 14px, 15px);
      cursor: pointer;
      padding: 8px 12px;
      border: none;
      border: 1px solid #000000;
      background-color: transparent;
      -webkit-filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.17));
      filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.17));
      border-radius: 8px;
      &:hover {
        opacity: 0.7;
      }
    }
    & ul {
      display: flex;
      gap: 10px;
      text-decoration: underline;
      @media (max-width: 680px) {
        flex-direction: column;
        align-items: center;
      }
      & li {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        position: relative;
        cursor: pointer;
        &::before {
          content: '';
          width: 100%;
          height: 1px;
          background-color: #003865;
          bottom: 0;
          position: absolute;
        }
        &:last-child {
          color: #d71f4b;
        }
        &:last-child {
          &::before {
            background-color: #d71f4b;
          }
        }
      }
    }
  }
  &__options {
    position: absolute;
    right: 18px;
    top: 22px;
    cursor: pointer;
    z-index: 3;
  }
  @media (max-width: 651px) {
    text-align: center;
    & .card__main {
      flex-direction: column;
      &-logo {
        margin: 0 auto 10px;
      }
      &-info {
        order: 2;
        &-text {
          flex-direction: column;
          justify-content: center;
          padding-right: 0;
          width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content;
          margin: 0 auto 10px;
        }
        &-types {
          width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content;
          & div:first-child {
            margin-left: 0;
          }
        }
      }
    }
    &__left {
      &-container {
        margin: 0 auto;
      }
    }
    &__handlers {
      position: absolute;
      right: 18px;
      top: 52px;
      background: #ffffff;
      border: 1px solid rgba(0, 56, 101, 0.1);
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      z-index: 2;
      & ul {
        text-decoration: none;
        padding: 12px 0;
        & li {
          padding: 6px 20px;
          @include text('Noto Sans', normal, 500, 16px, 24px);
          &::before {
            display: none;
          }
          & a {
            &:active,
            &:visited {
              color: $black !important;
            }
          }
        }
      }
    }
  }
}
