.cards {
  margin: 0 auto;
  // min-height: 2200px;
  width: 100%;
  &__popup {
    width: 392px;
    padding: 32px;
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    &__title {
      @include text('Noto Sans', normal, 600, 18px, 25px);
      margin-bottom: 10px;
    }
    & p {
      @include text('Noto Sans', normal, 500, 16px, 21px);
      color: #003865;
    }
    & label {
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      display: block;
      position: relative;
      & svg {
        position: absolute;
        top: 20px;
        left: 18px;
      }
      & textarea {
        @include text('Noto Sans', normal, 400, 15px, 20px);
        color: $black;
        width: 100%;
        margin: 12px 0 18px;
        border: 1px solid rgba(7, 7, 7, 0.3);
        border-radius: 8px;
        padding: 10px 16px;
        &::-webkit-input-placeholder {
          color: rgba(7, 7, 7, 0.5);
        }
        &::-moz-placeholder {
          color: rgba(7, 7, 7, 0.5);
        }
        &:-ms-input-placeholder {
          color: rgba(7, 7, 7, 0.5);
        }
        &::-ms-input-placeholder {
          color: rgba(7, 7, 7, 0.5);
        }
        &::placeholder {
          color: rgba(7, 7, 7, 0.5);
        }
      }
    }

    &__form {
      margin-top: 8px;
    }

    & input[type='submit'] {
      background: #003865;
      border-radius: 8px;
      padding: 12px 16px;
      outline: none;
      width: 100%;
      border: none;
      color: #fff;
      @include text('Noto Sans', normal, 800, 15px, 20px);
    }
  }
}
.card {
  max-width: 1032px;
  position: relative;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 0 auto 16px;
  &__page {
    background: transparent;
    box-shadow: unset;
    & .card__main {
      align-items: center;
      background-color: #f9f9f9 !important;
      &-logo {
        margin-left: 15px;
        min-width: 140px;
        min-height: 140px;
      }
      @media (max-width: 501px) {
        flex-direction: column;
        & .card__main-info {
          order: 2;
          text-align: center;
          margin-bottom: 16px;
          &-text {
            flex-direction: column;
            margin: 8px 0 6px;
            text-align: center;
          }
          &-types {
            margin: 2px auto 0;
          }
        }
        & .card__main-logo {
          margin: 0 auto 16px;
        }
      }
    }
    & .card__more {
      background-color: #f9f9f9 !important;
    }
  }
  &.pending {
    border-bottom: 3px solid $orange;
  }
  &.access {
    border-bottom: 3px solid green;
  }
  &.reject {
    border-bottom: 3px solid #d71f4b;
  }
  &__popup {
    &__select {
      font-family: 'Noto Sans', sans-serif;
    }
  }
  &-vip {
    position: absolute;
    left: 0;
    height: 100%;
    width: 16px;
    padding: 14px 1px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 0;
    bottom: 0;
    background: $orange;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    & p {
      transform: rotate(-180deg);
      & span {
        display: flex;
        gap: 8px;
        -webkit-writing-mode: vertical-rl;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        @include text('Roboto', normal, 500, 12px, 14px);
        color: #fff;
      }
    }
  }
  &__main {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    &-info {
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      &-breadcumbs {
        @include text('Roboto', normal, 400, 13px, 15px);
        color: $lightBlack;
      }
      &-text {
        cursor: pointer;
        display: flex;
        gap: 6px 18px;
        padding-right: 30px;
        margin: 15px 0 0;
        & h3 {
          @include text('Roboto', normal, 600, 18px, 21px);
        }
        @media (max-width: 491px) {
          flex-direction: column;
          margin-bottom: 10px;
        }
      }
      &-types {
        display: flex;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        flex-wrap: wrap;
        gap: 8px;
        & div {
          min-width: 80px;
          text-align: center;
          height: -webkit-fit-content;
          height: -moz-fit-content;
          height: fit-content;
          padding: 4px 6px;
          @include text('Roboto', normal, 400, 14px, 16px);
          border-radius: 6px;
        }
        &-city,
        &-checking {
          color: #003865;
          background: rgba(0, 56, 101, 0.15);
        }
        &-state {
          color: $orange;
          background: rgba(255, 179, 16, 0.209);
        }
        &-forself,
        &-access {
          color: #23a734;
          background: rgba(35, 167, 52, 0.15);
        }
        &-reject,
        &-archive {
          color: #d71f4b;
          background: rgba(215, 31, 75, 0.2);
        }
        &-reject,
        &-checking,
        &-access,
        &-archive {
          margin-left: 15px;
        }
      }
    }
    &-salary {
      @include text('Roboto', normal, 600, 18px, 21px);
    }
    &-fullname {
      margin-top: 14px;
      @include text('Roboto', normal, 600, none, none);
    }
    &-logo {
      overflow: hidden;
      border-radius: 50%;
      width: 100px;
      min-width: 100px;
      height: 100px;
      & img {
        -o-object-fit: cover;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
      @media (max-width: 620px) {
        width: 80px;
        min-width: 80px;
        height: 80px;
      }
      @media (max-width: 420px) {
        width: 60px;
        min-width: 60px;
        height: 60px;
      }
      @media (max-width: 380px) {
        width: 40px;
        min-width: 40px;
        height: 40px;
      }
    }
  }
  &__more {
    background-color: #fff;
    &-name {
      margin: 5px 0;
      white-space: nowrap;
      @include text('Roboto', normal, 400, 15px, 18px);
      color: $black;
      & span {
        color: $lightBlack;
      }
      & a {
        white-space: break-spaces;
        color: $black;
      }
    }
    &-description {
      @include text('Roboto', normal, 400, 15px, 18px);
      color: $black;
      &.twoLine {
        overflow: hidden;
        max-height: 36px;
        & li {
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          white-space: pre-wrap;
        }
      }
    }
  }
  &__handlers {
    margin-top: 15px;
    display: flex;
    align-items: center;
    gap: 20px;
    &-fav {
      cursor: pointer;
    }
    & button,
    &-submit {
      @include text('Noto Sans', normal, 800, 15px, 20px);
      padding: 12px 32px;
      background: $orange;
      color: #fff;
      outline: none;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.17);
      border-radius: 8px;
      border: none;
      cursor: pointer;
      &:disabled {
        background: #ced3d9 !important;
        color: #fff !important;
        border: 1px solid #ced3d9 !important;
      }
      @media (max-width: 489px) {
        padding: 10px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
      }
    }
    & button {
      &.active {
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
      }
    }
    // &-invited {
    //   &.active {
    //     color: #23a734 !important;
    //     background: rgba(35, 167, 52, 0.15) !important;
    //   }
    // }
    // &-denied {
    //   &.active {
    //     color: #d71f4b !important;
    //     background: rgba(215, 31, 75, 0.2) !important;
    //   }
    // }
    & > div {
      display: flex;
      align-items: center;
      gap: 8px;
      @include text('Roboto', normal, 400, 13px, 15px);
      color: $lightBlack;
      & svg {
        // fill: rgb(117, 112, 112);
        cursor: pointer;
        width: 25px;
        height: 25px;
      }
      // & svg :hover {
      //   fill: gold;
      // }
    }
  }
  &__moreInfo {
    padding-top: 20px;
    width: 100%;
    &-up {
      gap: 16px;
      display: flex;
      flex-wrap: wrap;
      &.resume {
        & .card__moreInfo-part {
          width: 100%;
        }
        & .card__moreInfo-list {
          padding-left: 24px;
        }
      }
    }
    &-part {
      width: calc(50% - 8px);
      min-width: 290px;
      & h4 {
        display: flex;
        gap: 8px;
        @include text('Roboto', normal, 500, 15px, 18px);
        color: $orange;
      }
    }
    &-footer {
      margin-top: 18px;
      & h3 {
        @include text('Roboto', normal, 500, 15px, 18px);
        margin-bottom: 10px;
      }
      &-socs {
        display: flex;
        gap: 8px;
        & button {
          background: transparent;
          cursor: pointer;
          outline: none;
          border: none;
          width: 28px;
          height: 28px;
          border-radius: 3px;
          overflow: hidden;
        }
      }
    }
    &-contact {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-top: 8px;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      padding: 12px 16px;
      border: 2px solid rgba(7, 7, 7, 0.4);
      border-radius: 8px;
      @include text('Roboto', normal, 400, 15px, 18px);
      &--gold {
        border-color: #ffb310;
      }
      & li {
        display: flex;
        align-items: center;
        & b,
        & a {
          margin-left: 10px;
          color: $black;
          font-weight: 500;
        }
        & a {
          cursor: pointer;
          &:hover {
            color: $orange;
          }
        }
      }
      &-showBtn {
        cursor: pointer;
        padding: 10px 12px;
        border-radius: 8px;
        border: 1px solid $black;
        margin-top: 12px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        @include text('Roboto', normal, 500, 16px, 21px);
        color: $black;
        &:hover {
          border: 1px solid $orange;
          color: $orange;
        }
      }
    }
    &-list {
      margin-top: 8px;
      list-style-type: circle;
      padding-left: 42px;
      & li,
      & p {
        position: relative;
        margin-bottom: 7px;
        @include text('Roboto', normal, 400, 15px, 18px);
      }
      & > p::before {
        content: '';
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        padding: 2px;
        border-radius: 50%;
        left: -10px;
        background-color: $black;
      }
    }
  }
}

.card_work {
  width: 300px;
  height: 130px;
}
.wrap_workpage {
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;
  margin: 0 auto;
}
.admin_vac_top {
  width: 100%;
}
.to_right {
  margin-left: 15px;
}

@media print {
  * {
    box-shadow: unset;
  }
  aside,
  header,
  footer,
  jdiv,
  .preHeader,
  .card__handlers,
  .card__moreInfo-footer {
    display: none !important;
  }
  .contentContent {
    position: relative;
    padding: 0 0 32px;
  }
}
