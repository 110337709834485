.headerSearch {
  position: relative;
  padding: 44px 96px;
  background: #04062b url(../../../assets/images/searchBg.png) center/cover
    no-repeat;
  height: 320px;
  border-radius: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &.jeep {
    padding: 20px 96px 32px 96px;
  }
  &__title {
    @include text('Noto Sans', normal, 600, 22px, 30px);
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
  }
  &__form {
    font-family: 'Noto Sans';
    transition: all 0.3s ease;
    position: relative;
    display: flex;
    height: 42px;
    gap: 8px;
    &-focus {
      width: calc(100% - 72px);
      height: 38px;
      position: absolute;
      z-index: 1;
    }
    &-left,
    &-right {
      display: flex;
      gap: 8px;
      @include text('Noto Sans', normal, 400, 15px, 20px);
    }
    &-left {
      display: flex;
      flex-wrap: wrap;
      max-width: 410px;
      & .css-b62m3t-container,
      .headerSearch__form-submitBtn {
        max-width: 160px;
        height: 38px;
        width: calc(33.333% - 5.333px);
      }
      & .css-b62m3t-container:first-child {
        width: 100%;
        max-width: unset;
      }
      @media (max-width: 1267px) {
        width: 100%;
        max-width: unset;
        max-height: unset;
        height: auto;
        flex-wrap: nowrap;
        z-index: 2;
        & .headerSearch__form-initial {
          z-index: unset;
          max-width: calc(40% - 5.333px);
          width: 100%;
          // & .dropdown-content {
          //   z-index: 3;
          // }
        }
      }
      @media (max-width: 860px) {
        flex-direction: column;
        height: 126px;
        & .headerSearch__form-initial {
          max-width: unset;
          width: 100%;
        }
      }
    }
    &-right {
      max-width: 640px;
      display: flex;
      flex-wrap: wrap;
      & .css-b62m3t-container,
      .headerSearch__form-submitBtn,
      .rmsc {
        max-width: 208px;
        width: 100%;
        height: 38px;
      }
    }
    &-submitBtn {
      font-weight: 800;
      height: 40px;
      background: $orange;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.17);
      outline: none;
      border: none;
      border-radius: 8px;
      color: #fff;
      cursor: pointer;
      &:hover {
        background: $hoverOrange;
      }
    }
    &-moreBtn {
      position: absolute;
      top: calc(100% + 20px);
      display: flex;
      align-items: center;
      gap: 6px;
      cursor: pointer;
      @include text('Noto Sans', normal, 600, 14px, 19px);
      color: #fff;
      // @media (max-width: 1267px) {
      //   z-index: 2;
      // }
    }
    &-initial {
      z-index: 4;
    }
    &-hidden {
      transform: translateY(calc(-100% - 8px));
      transition: all 0.3s ease;
      z-index: -1;
      &.onePart {
        transform: translateY(calc(-200% - 16px));
        max-width: unset;
        width: calc(50% - 4px);
        &.last {
          width: calc(100% - 216px);
        }
      }
    }
    &.show {
      height: 84px;
      @media (max-width: 1267px) {
        height: 42px;
      }
      &.resume {
        height: 130px;
      }
    }
    &.show &-hidden {
      z-index: 2;
      transform: translateY(0);
      transition: all 0.3s ease;
      &.secondInitial {
        z-index: 3 !important;
      }
    }
  }
  @media (max-width: 860px) {
    padding: 44px 16px;
    &__form {
      height: unset;
      &-submitBtn {
        z-index: 0;
      }
    }
  }
}

.handlePopup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.88);
  z-index: 1000;
  padding: 80px 24px;
  overflow-y: scroll;
  &.none {
    display: none;
  }
  &.bg-unset {
    background: rgba(0, 0, 0, 0.17);
  }
  &__close {
    position: absolute;
    top: 35px;
    right: 30px;
    cursor: pointer;
  }
  &__content {
    max-width: 300px;
    margin: 0 auto;
    max-height: 80vh;
    &.unsize {
      position: absolute;
      min-width: 800px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: unset;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      background-color: #fff;
      padding: 20px;
      border-radius: 10px;
      &.preview {
        background: transparent;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        max-width: 1032px;
        & .card {
          width: 100%;
          min-width: 920px;
        }
      }
    }
    & .headerSearch {
      &__form {
        height: unset;
        width: 100%;
        flex-direction: column;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        gap: 16px;
        &-initial {
          z-index: unset;
          min-height: 38px !important;
        }
        &-moreBtn {
          position: static;
          margin-bottom: 32px;
        }
        & label {
          color: #fff;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }
}
