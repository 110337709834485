.messages {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 66px;
  height: calc(100% - 66px);
  display: flex;
  align-items: stretch;
  overflow: hidden;
  &__title {
    @include text('Noto Sans', normal, 600, 20px, 27px);
    text-align: center;
    margin: 16px 0;
  }
  &__text {
    @include text('Noto Sans', normal, 400, 15px, 20px);
    text-align: center;
  }
  &-layout {
    padding: 24px 0;
    width: 100%;
    height: 100%;
    max-width: 436px;
    @media (max-width: 581px) {
      max-width: unset;
      padding-bottom: 90px;
    }
    &__top {
      padding: 0 27px 10px;
    }
    &__aside {
      height: 100%;
    }
    &__text {
      @include text('Noto Sans', normal, 600, 20px, 27px);
      color: $black;
    }
    &__search {
      display: block;
      margin-top: 16px;
      & span:last-child {
        background: rgba(0, 56, 101, 0.06);
        border-radius: 10px;
        max-width: 464px;
        min-width: 200px;
        width: 100%;
        padding: 10px 20px;
        display: flex;
        align-items: center;
      }
      & input {
        margin-left: 12px;
        width: 100%;
        border: none;
        outline: none;
        background: transparent;
        @include text('Noto Sans', normal, 400, 14px, 19px);
      }
    }
    &__chats-list {
      overflow-y: scroll;
      height: calc(100% - 92px);
    }
    &__chat {
      position: relative;
      background: #f9f9f9;
      padding: 8px 38px 8px 16px;
      &::before {
        content: '';
        position: absolute;
        width: calc(100% - 86px);
        height: 1px;
        background-color: rgba(7, 7, 7, 0.1);
        right: 0;
        top: 100%;
        z-index: 1;
      }
      &--active,
      &:hover {
        background: rgba(0, 56, 101, 0.1);
      }
      &-main {
        display: flex;
        align-items: start;
        max-height: 76px;
        gap: 10px;
      }
      &-avatar {
        width: 60px;
        height: 60px;
        -o-object-fit: cover;
        object-fit: cover;
        border-radius: 50%;
      }
      &-info {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      &-title {
        @include text('Noto Sans', normal, 500, 16px, 21px);
        color: $black;
      }
      &-message {
        @include text('Noto Sans', normal, 400, 15px, 20px);
        color: $gray;
        display: -webkit-box;
        max-width: 250px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &-wrapper {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: end;
        gap: 9px;
      }
      &-time {
        @include text('Noto Sans', normal, 400, 15px, 20px);
        color: $gray;
        white-space: nowrap;
      }
      &-new {
        @include text('Noto Sans', normal, 400, 14px, unset);
        color: #fff;
        background: #037ee5;
        border-radius: 12px;
        padding: 2px 5px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
      }
    }
  }
  &__companion {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    align-self: flex-start;
    background: #ffffff;
    border-bottom: 1px solid #d9dce0;
    padding: 8px 16px;
    display: flex;
    gap: 16px;
    align-items: flex-start;
    &-ava {
      & img {
        width: 40px;
        height: 40px;
        -o-object-fit: cover;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    &-name {
      @include text('Noto Sans', normal, 600, 16px, 21px);
      color: $black;
    }
    &-status {
      @include text('Noto Sans', normal, 400, 14px, 18px);
      color: $black;
    }
  }
  &-content {
    position: relative;
    width: 100%;
    min-width: 50%;
    background: url('../../../assets/images/chat/bg.png'),
      linear-gradient(0deg, rgba(196, 209, 224, 0.4), rgba(196, 209, 224, 0.4));
    &--mobile {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      height: 100%;
      overflow: hidden;
      z-index: 100;
    }
  }
  &__form {
    position: absolute;
    bottom: 32px;
    right: 0;
    left: 0;
    margin: 0 16px;
    max-height: 50%;
    overflow-y: scroll;
    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    border-top: 1px solid #d9dce0;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    gap: 16px;
    &-emoji {
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    &-picker {
      position: absolute;
      left: 16px;
      & em-emoji-picker {
        height: 200px;
      }
    }
    &-label {
      width: 100%;
      display: grid;
      place-items: center;
      padding: 5px 0;
      & textarea {
        padding: 8px 0;
        width: 100%;
        border: none;
        outline: none;
        resize: none;
        @include text('Noto Sans', normal, 400, 16px, 20px);
      }
    }
    &-submit {
      cursor: pointer;
      width: 24px;
      height: 24px;
      background-color: transparent;
      border: none;
      outline: none;
    }
  }
  &__list {
    height: calc(100% - 138px);
    margin: 56px auto 82px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 16px;
  }
  &__loading {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80px;
    height: 80px;
    transform: translate(-50%, -50%);

    &-part {
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: $darkBlue;
      border-radius: 50%;
      -webkit-animation: loading 4s infinite;
      animation: loading 4s infinite;
    }

    &-part:nth-child(1) {
      -webkit-animation-delay: 0.2s;
      animation-delay: 0.2s;
      width: 10px;
      height: 10px;
      left: -30px;
    }

    &-part:nth-child(2) {
      -webkit-animation-delay: 0.4s;
      animation-delay: 0.4s;
      width: 10px;
      height: 10px;
    }

    &-part:nth-child(3) {
      -webkit-animation-delay: 0.6s;
      animation-delay: 0.6s;
      width: 10px;
      height: 10px;
      right: -30px;
    }

    &-part {
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }

    @-webkit-keyframes loading {
      0% {
        transform: translateY(0px);
      }
      20% {
        transform: translateY(0px);
      }
      30% {
        transform: translateY(-8px);
      }
      40% {
        transform: translateY(5px);
      }
      50% {
        transform: translateY(-2px);
      }
      60% {
        transform: translateY(2px);
      }
      80% {
        transform: translateY(0px);
      }
      100% {
        transform: translateY(0px);
      }
    }

    @keyframes loading {
      0% {
        transform: translateY(0px);
      }
      20% {
        transform: translateY(0px);
      }
      30% {
        transform: translateY(-8px);
      }
      40% {
        transform: translateY(5px);
      }
      50% {
        transform: translateY(-2px);
      }
      60% {
        transform: translateY(2px);
      }
      80% {
        transform: translateY(0px);
      }
      100% {
        transform: translateY(0px);
      }
    }
  }
  &__toast {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 300px;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 15px 10px;
    border-radius: 8px;
    box-shadow: 0px 10px 10px -10px;
    background: #fff;
    &--error {
      .messages__toast-title {
        color: #d71f4b;
      }
    }
    &--connect {
      .messages__toast-title {
        color: $orange;
      }
    }
    &-close {
      position: absolute;
      width: 25px;
      min-height: 25px;
      right: 0;
      top: 0;
      transform: translate(-25%, 25%);
      cursor: pointer;
      display: grid;
      place-items: center;
      border-radius: 2px;
      background-color: #fff;
      &:hover {
        -webkit-filter: brightness(90%);
        filter: brightness(90%);
      }
    }
    &-title {
      @include text('Noto Sans', normal, 600, 18px, 18px);
      margin-bottom: 6px;
    }
    &-text {
      @include text('Noto Sans', normal, 400, 16px, 18px);
    }
    &-btns {
      display: flex;
      gap: 20px;
      justify-content: center;
    }
    &-btn {
      padding: 6px 14px;
      border: none;
      border-radius: 4px;
      @include text('Noto Sans', normal, 500, 17px, 20px);
      cursor: pointer;
      margin-top: 10px;
      color: $darkBlue;
      background-color: #e9e9ed;
      &--not {
        color: #d71f4b;
        background-color: #d71f4a18;
      }
      &-container {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
      &-loading {
        background-color: $darkBlue;
        min-width: 100%;
        height: 5px;
        position: relative;
        overflow: hidden;
      }
      &-loading::after {
        content: '';
        height: 5px;
        position: absolute;
        background: #fff;
        transform: translateX(-100%);
        -webkit-animation: animate 2s infinite;
        animation: animate 2s infinite;
      }
      &-loading::before {
        content: '';
        height: 5px;
        position: absolute;
        background: #fff;
        transform: translateX(-100%);
        -webkit-animation: animate 2s infinite;
        animation: animate 2s infinite;
        -webkit-animation-delay: 1s;
        animation-delay: 1s;
      }
      @-webkit-keyframes animate {
        0% {
          transform: translateX(-220%);
          min-width: 100px;
        }
        100% {
          transform: translateX(300%);
          min-width: 400px;
        }
      }
      @keyframes animate {
        0% {
          transform: translateX(-220%);
          min-width: 100px;
        }
        100% {
          transform: translateX(300%);
          min-width: 400px;
        }
      }
    }
  }
  &__by-date {
    padding: 0 16px;
    margin: 24px 0;
    &:last-child {
      margin: 24px 0 0;
      border-radius: 12px;
    }
    & h2 {
      text-align: center;
      @include text('Noto Sans', normal, 400, 16px, 20px);
      margin-bottom: 24px;
    }
  }
  &__by-list {
    display: flex;
    flex-direction: column;
    gap: 17px;
  }
  &__text-message {
    padding: 4px 12px;
    border-radius: 12px;
    max-width: 60%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    @include text('Roboto', normal, 400, 16px, 21px);
    &--companion {
      background: #fff;
      align-self: flex-start;
    }
    &--mine {
      background: #e1fec6;
      align-self: flex-end;
    }
    @media (max-width: 581px) {
      max-width: 80%;
    }
    &-context {
      position: fixed;
      overflow: hidden;
      background: #ffffff;
      border: 1px solid rgba(0, 56, 101, 0.1);
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      &--mine {
        transform: translate(-100%, -100%);
      }
      &--companion {
        transform: translate(0, -100%);
      }
      & ul {
        & li {
          cursor: pointer;
          white-space: nowrap;
          @include text('Noto Sans', normal, 500, 15px, 20px);
          padding: 5px 10px;
          &:hover {
            background-color: rgba(0, 56, 101, 0.1);
          }
        }
      }
    }
    &-info {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 5px;
      & span {
        @include text('Inter', normal, 400, 12px, 16px);
        color: rgba(7, 7, 7, 0.7);
      }
    }
  }
}
