.vacancesList {
  position: relative;
  border: 1px solid #003865;
  border-radius: 8px;
  max-width: 100%;
  width: 255px;
  height: 44px;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  overflow: hidden;
  margin-bottom: 64px;
  background: rgba(0, 56, 101, 0.15);
  &.sub {
    width: unset;
    border: none;
    background: #f9f9f9;
    height: unset;
    .vacancesList__backLink {
      align-items: center;
      gap: 5px;
      cursor: pointer;
      position: absolute;
      @include text('Noto Sans', normal, 600, 15px, 20px);
      top: 12px;
      left: 16px;
      display: flex;
      z-index: 2;
    }
    .vacancesList__group {
      & ul {
        overflow-x: scroll;
        max-height: 234px;
      }
    }
    .vacancesList__title {
      margin: 0 auto;
      @include text('Noto Sans', normal, 500, 20px, 27.24px);
      color: $black;
      text-align: center;
      padding: 12px 45px;
      white-space: break-spaces;
    }
  }
  &__title {
    white-space: nowrap;
    padding: 12px 16px;
    display: flex;
    position: -webkit-sticky;
    position: sticky;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    @include text('Noto Sans', normal, 600, 15px, 20px);
    color: $darkBlue;
    cursor: pointer;
    gap: 20px;
  }
  &__group {
    padding: 14px 24px;
    overflow-x: scroll;
    & ul {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      row-gap: 16px;
      -moz-column-gap: 40px;
      column-gap: 40px;
      max-height: 344px;
      & li {
        max-width: 312px;
        color: $orange;
        @include text('Noto Sans', normal, 400, 15px, 20px);
        & a {
          position: relative;
          color: $darkBlue;
          &::before {
            content: '';
            position: absolute;
            bottom: -2px;
            left: 0;
            right: 0;
            width: 0;
            height: 1px;
            background-color: $darkBlue;
          }
          &.active::before {
            width: 100%;
          }
        }
      }
    }
  }
  &.show {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: 100%;
    background: #f9f9f9;
  }
  &__pop {
    &-title {
      @include text('Noto Sans', normal, 600, 19px, 26px);
      color: #fff;
      margin-bottom: 16px;
    }
    &-input {
      padding: 12px 16px;
      background: transparent;
      border: 1px solid #ffffff;
      border-radius: 8px;
      color: #fff;
      width: 100%;
      outline: none;
      margin: 16px 0;
    }
    &-options {
      height: calc(100vh - 220px);
      overflow-y: scroll;
      & li {
        padding: 10px 16px;
        background: #fff;
        border-radius: 9px;
        margin-bottom: 16px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        & a {
          @include text('Noto Sans', normal, 400, 16px, 22px);
          color: $black;
          & span {
            color: $orange;
          }
        }
      }
    }
  }
}
