.agreement {
  &__title {
    @include text('Noto Sans', normal, 700, 20px, 27px);
    text-align: center;
    margin: 20px 0;
  }
  &__content {
    max-width: 1048px;
    margin: 0 auto;
    padding: 0 20px;
    font-family: 'Noto Sans';
    font-style: normal;
    font-size: 16px;
    & h3 {
      font-weight: 700;
      font-size: 16px;
    }
    & a {
      font-weight: 700;
      color: $darkBlue;
      text-decoration: underline;
    }
  }
  &__form {
    margin-top: 36px;
    display: flex;
    align-items: center;
    padding: 16px;
    background: rgba(206, 211, 217, 0.2);
    border-radius: 6px;
    width: 100%;
    max-width: 466px;
    gap: 10px;
    @media (max-width: 661px) {
      margin: 36px auto;
    }
    &-checkbox {
      width: 24px !important;
      min-width: 24px !important;
      height: 24px;
      min-height: 24px;
      cursor: pointer;
      & input {
        visibility: hidden;
        display: none;
        opacity: 0;
      }
      & input:checked ~ i::before {
        background: #003865 url('../../../assets/svg/check.svg') center/14px
          no-repeat;
      }
      & i {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
      }
      & i::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border: 1px solid rgba(7, 7, 7, 0.3);
        border-radius: 6px;
      }
    }
    & a {
      color: $black;
      text-decoration: underline;
      @include text('Noto Sans', normal, 400, 15px, 20px);
    }
  }
}
