.boundary {
  text-align: center;
  font-family: 'Noto Sans';
  font-style: normal;
  &__content {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
  &__media {
    height: 50vh;
  }
  &__text {
    margin-bottom: 10px;
  }
  &__home {
    padding: 8px 12px;
    background: rgba(0, 56, 101, 0.15);
    border-radius: 3px;
    color: #003865;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    cursor: pointer;
  }
  &__login {
    cursor: pointer;
    background: transparent;
    border: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 15px auto 0;
    font-size: 16px;
    &:hover {
      color: $orange;
    }
  }
}
