.crudAdmin {
    display: flex;
    gap: 30px;
    @media (max-width: 841px) {
      flex-direction: column;
      &__popup {
        transform: unset;
        bottom: 126px !important;
        height: 100%;
        z-index: 101 !important;
      }
      &__nav {
        max-width: unset !important;
      }
      &__list {
        flex-direction: row !important;
        &-item {
          width: calc(25% - 6px);
          height: 4px;
          &::before,
          i,
          span {
            display: none !important;
          }
          &.active {
            background: #ffb310;
          }
          &.hidden {
            background: rgba(0, 56, 101, 0.15);
          }
        }
      }
      &__template {
        justify-content: center;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: 0 auto;
      }
    }
    @media (max-width: 661px) {
      &__template {
        flex-direction: column;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        &-btns {
          width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content;
          margin: 0 auto;
        }
        &-wrapper {
          width: 100%;
          margin: 0 auto;
        }
      }
    }
    &__subtitle {
      @include text('Noto Sans', normal, 600, 16px, 22px);
      display: flex;
      justify-content: space-between;
      margin-bottom: 3px;
      align-items: center;
      max-width: 850px;
      & button {
        border: 1px solid $darkBlue;
        @include text('Noto Sans', normal, 800, 15px, 18px);
        border-radius: 8px;
        color: $darkBlue;
        padding: 10px 12px;
        cursor: pointer;
        background-color: transparent;
      }
    }
    &__desc {
      @include text('Noto Sans', normal, 400, 14px, 19px);
      margin: 3px 0 18px;
    }
    &__table {
      transition: 0.3s;
      width: 100%;
      max-width: 850px;
      margin-top: 14px;
      background-color: #fff;
      border-collapse: collapse;
      border: 1px solid rgba(7, 7, 7, 0.12);
      border-radius: 4px;
      text-align: left;
      & th,
      & td {
        min-width: 144px;
      }
      & thead {
        border-radius: 4px;
        background: rgba(0, 56, 101, 0.15);
        & th {
          min-width: 120px;
          padding: 10px 14px;
          @include text('Noto Sans', normal, 500, 14px, 24px);
        }
      }
      & tbody {
        & td {
          min-width: 136px;
          @include text('Noto Sans', normal, 400, 14px, 20px);
          padding: 10px 14px;
          &.action {
            @include text('Noto Sans', normal, 500, 14px, 24px);
            cursor: pointer;
            & p {
              text-align: center;
              &:first-child {
                color: $orange;
              }
              &:last-child {
                color: #d71f4b;
              }
              &:hover {
                text-decoration: underline;
              }
            }
          }
          & a {
            white-space: nowrap;
            color: $black;
            text-decoration: underline;
          }
          & input {
            padding: 5px 6px;
            border-radius: 8px;
            border: 1px solid rgba(0, 56, 101, 0.15);
          }
        }
      }
    }
    &__main {
      min-height: 80vh;
      width: 100%;
    }
    &__nav {
      max-width: 234px;
    }
    &__list {
      &.create {
        gap: 56px;
      }
      display: flex;
      flex-direction: column;
      gap: 24px;
      &-item {
        position: relative;
        &:before {
          position: absolute;
          top: 100%;
          left: 15px;
          content: '';
          height: 56px;
          margin-left: 0.5px;
        }
        &:last-child {
          &:before {
            display: none;
          }
        }
        &.active {
          &::before {
            border-left: 2px dashed #003865;
          }
        }
        &.hidden {
          cursor: auto;
          &::before {
            border-left: 2px dashed rgba(0, 56, 101, 0.15);
          }
          & i {
            background: rgba(0, 56, 101, 0.15);
            & svg {
              display: none;
            }
          }
          & span {
            display: none;
          }
        }
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 12px;
        @include text('Noto Sans', normal, 500, 14px, 16px);
        & i {
          display: block;
          width: 32px;
          min-width: 32px;
          height: 32px;
          border-radius: 50%;
          padding: 8px;
          background: $orange;
          & svg {
            width: 100%;
            height: 100%;
          }
        }
        & span {
          color: #003865;
          &.active {
            color: $orange;
          }
        }
      }
    }
    &__handlers {
      display: flex;
      gap: 8px;
      flex-direction: column;
    }
    &__addBtn {
      display: flex;
      gap: 8px;
      align-items: center;
      cursor: pointer;
      &-icon {
        width: 24px;
        height: 24px;
        padding: 2.5px 0 0px 1px;
        text-align: center;
        cursor: pointer;
        border: 1px solid rgba(255, 179, 16, 0.5);
        border-radius: 6px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        color: $orange;
      }
      & span {
        @include text('Noto Sans', normal, 400, 14px, 19px);
        color: $orange !important;
      }
    }
    &__removeBtn {
      display: flex;
      gap: 8px;
      align-items: center;
      cursor: pointer;
      &-icon {
        width: 24px;
        height: 24px;
        padding: 2.5px 0 0px 1px;
        text-align: center;
        cursor: pointer;
        border: 1px solid rgba(255, 179, 16, 0.5);
        border-radius: 6px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        color: $orange;
      }
      & span {
        @include text('Noto Sans', normal, 400, 14px, 19px);
        color: $orange !important;
      }
    }
    &__template {
      width: 100%;
      display: flex;
      justify-content: start;
      align-items: start;
      gap: 24px;
      @include text('Noto Sans', normal, 400, 14px, 19px);
      & input,
      & textarea {
        width: 100%;
        padding: 10px 14px;
        border: 1px solid rgba(7, 7, 7, 0.2);
        border-radius: 6px;
      }
      & .select {
        width: 100%;
        min-width: 104px;
      }
      &-item,
      & label {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
        &.required {
          position: relative;
          &::after {
            content: '*';
            color: rgb(255, 95, 95);
            position: absolute;
            top: 0;
            right: 0;
          }
        }
        &.row {
          &-wrapper {
            gap: 8px;
          }
        }
        &-addMode {
          display: flex;
          gap: 8px;
          align-items: center;
        }
        &-list {
          overflow-x: scroll;
          display: flex;
          width: calc(100% - 32px);
          gap: 8px;
          box-shadow: inset -20px 0 9px -7px rgba(0, 0, 0, 0.3);
          -ms-overflow-style: none;
          scrollbar-width: none;
          border-radius: 6px;
          &::-webkit-scrollbar {
            display: none;
          }
          & div {
            white-space: nowrap;
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content;
            display: flex;
            align-items: center;
            height: 40px;
            justify-content: space-between;
            padding: 0 10px 0 16px;
            background-color: rgba(0, 56, 101, 0.35);
            min-height: 40px;
            border-radius: 6px;
            line-height: 14px;
          }
          & svg {
            cursor: pointer;
          }
        }
        & h4 {
          @include text('Noto Sans', normal, 600, 16px, 22px);
          margin-top: 16px;
        }
        & span {
          @include text('Noto Sans', normal, 400, 14px, 19px);
          color: rgba(7, 7, 7, 0.7);
        }
        & button {
          max-width: 240px;
          padding: 10px;
          border-radius: 8px;
          background: $orange;
          border: none;
          @include text('Noto Sans', normal, 500, 15px, 20px);
          cursor: pointer;
          color: #fff;
        }
        &-wrapper {
          display: flex;
          gap: 16px;
          &.selects {
            gap: 8px;
            &__three {
              & .select {
                width: calc(33% - 5px);
              }
            }
            &__two {
              & .select:first-child {
                width: 100%;
                text-align: center;
              }
              & .select:last-child {
                width: 104px;
              }
            }
          }
          & label {
            cursor: pointer;
            position: relative;
            gap: 4px;
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content;
            align-items: center;
            flex-direction: row;
            @include text('Noto Sans', normal, 400, 14px, 19px);
            color: rgba(0, 56, 101, 0.8);
            & input {
              width: 18px;
              height: 0;
              opacity: 0;
            }
            & input:checked + i::before {
              background: $orange url('../../../assets/svg/check.svg') center/10px
                no-repeat;
              border: 1px solid $orange;
            }
            & i::before {
              content: '';
              width: 16px;
              height: 16px;
              position: absolute;
              border-radius: 50%;
              left: 0;
              top: 0;
              border: 1px solid rgba(0, 56, 101, 0.6);
            }
          }
        }
      }
      &-wrapper {
        max-width: 352px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
      &-btns {
        margin-top: 18px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        &.nav {
          justify-content: space-around;
          width: 100%;
          flex-direction: row;
          & button {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            color: $black;
          }
        }
        & button {
          max-width: 350px;
          @include text('Noto Sans', normal, 800, 15px, 20px);
          padding: 12px;
          border-radius: 8px;
          border: none;
          color: #fff;
          background: $orange;
          cursor: pointer;
          &:disabled {
            background: #ced3d9;
          }
        }
        &._next {
          justify-content: end;
          flex-direction: row;
          & button {
            max-width: 208px;
            width: 100%;
          }
        }
      }
    }
    &__popup {
      width: 100%;
      max-width: 658px;
      max-height: 506px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 24px 16px;
      background: #ffffff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      z-index: 10;
      &-title {
        padding-left: 18px;
        @include text('Noto Sans', normal, 600, 18px, 25px);
        margin-bottom: 8px;
      }
      &-wrapper {
        margin-top: 8px;
        display: flex;
        gap: 24px;
        &-col {
          max-height: 376px;
          width: calc(50% - 12px);
          &.sub_list {
            direction: unset;
            & form {
              height: 100%;
            }
            & ul {
              & li {
                direction: ltr;
                margin-bottom: 16px;
                cursor: pointer;
                position: relative;
                align-items: center;
                display: flex;
                gap: 8px;
                & input {
                  opacity: 0;
                  &:checked + label::before {
                    background-color: #003865;
                  }
                }
                & label {
                  &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    border-radius: 50%;
                    width: 16px;
                    height: 16px;
                    border: 1px solid rgba(0, 56, 101, 0.6);
                  }
                }
                & span {
                  padding: 0;
                }
              }
            }
          }
          & ul {
            padding-left: 16px;
            display: block;
            direction: rtl;
            height: 100%;
            overflow-y: scroll;
            & li {
              direction: ltr;
              text-align: start;
              @include text('Noto Sans', normal, 500, 14px, 19px);
              & span {
                width: -webkit-fit-content;
                width: -moz-fit-content;
                width: fit-content;
                padding: 8px;
                cursor: pointer;
                border-radius: 6px;
                display: block;
                &.choosed {
                  background: rgba(0, 56, 101, 0.9);
                  color: #fff;
                }
              }
            }
          }
        }
      }
      &-btns {
        display: flex;
        padding: 16px 18px 0;
        gap: 16px;
        & button {
          border: none;
          padding: 10px 20px;
          box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.17);
          border-radius: 8px;
          color: #fff;
          cursor: pointer;
          @include text('Noto Sans', normal, 800, 15px, 20px);
          background: #ffb310;
          &:disabled {
            background: #ced3d9;
          }
        }
      }
    }
  }
  
  .addResume__backBtnAdmin {
    margin: 10px 0 22px;
    display: flex;
    align-items: center;
    gap: 10px;
    @include text('Noto Sans', normal, 600, 15px, 20px);
    cursor: pointer;
  }
  .cardAdmin {
    max-width: 1032px;
    position: relative;
    padding: 20px;
    background: #ffffff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: 0 auto 16px;
    &__page {
      background: transparent;
      box-shadow: unset;
      & .card__main {
        align-items: center;
        &-logo {
          margin-left: 15px;
          min-width: 140px;
          min-height: 140px;
        }
        @media (max-width: 501px) {
          flex-direction: column;
          & .card__main-info {
            order: 2;
            text-align: center;
            margin-bottom: 16px;
            &-text {
              flex-direction: column;
              margin: 8px 0 6px;
              text-align: center;
            }
            &-types {
              margin: 2px auto 0;
            }
          }
          & .card__main-logo {
            margin: 0 auto 16px;
          }
        }
      }
    }
    &.pending {
      border-bottom: 3px solid $orange;
    }
    &.access {
      border-bottom: 3px solid green;
    }
    &.reject {
      border-bottom: 3px solid #d71f4b;
    }
    &__popup {
      &__select {
        font-family: 'Noto Sans', sans-serif;
      }
    }
    &-vip {
      position: absolute;
      left: 0;
      height: 100%;
      width: 16px;
      padding: 14px 1px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      top: 0;
      bottom: 0;
      background: $orange;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      & p {
        display: flex;
        gap: 8px;
        -webkit-writing-mode: vertical-rl;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        transform: rotate(-180deg);
        @include text('Roboto', normal, 500, 12px, 14px);
        color: #fff;
      }
    }
    &__main {
      display: flex;
      justify-content: space-between;
      &-info {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        &-breadcumbs {
          @include text('Roboto', normal, 400, 13px, 15px);
          color: $lightBlack;
        }
        &-text {
          cursor: pointer;
          display: flex;
          gap: 6px 18px;
          margin: 15px 0 0;
          & h3 {
            @include text('Roboto', normal, 600, 18px, 21px);
          }
        }
        &-types {
          display: flex;
          width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content;
          flex-wrap: wrap;
          gap: 8px;
          & div {
            min-width: 80px;
            text-align: center;
            height: -webkit-fit-content;
            height: -moz-fit-content;
            height: fit-content;
            padding: 4px 6px;
            @include text('Roboto', normal, 400, 14px, 16px);
            border-radius: 6px;
          }
          &-city,
          &-checking {
            color: #003865;
            background: rgba(0, 56, 101, 0.15);
          }
          &-forself,
          &-access {
            color: #23a734;
            background: rgba(35, 167, 52, 0.15);
          }
          &-reject,
          &-archive {
            color: #d71f4b;
            background: rgba(215, 31, 75, 0.2);
          }
          &-reject,
          &-checking,
          &-access,
          &-archive {
            margin-left: 15px;
          }
        }
      }
      &-salary {
        @include text('Roboto', normal, 600, 18px, 21px);
      }
      &-logo {
        overflow: hidden;
        border-radius: 50%;
        width: 80px;
        height: 80px;
        & img {
          -o-object-fit: cover;
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
        @media (max-width: 620px) {
          width: 40px;
          min-width: 40px;
          height: 40px;
        }
      }
    }
    &__more {
      &-name {
        margin: 5px 0;
        white-space: nowrap;
        @include text('Roboto', normal, 400, 15px, 18px);
        color: $black;
        & span {
          color: $lightBlack;
        }
        & a {
          white-space: break-spaces;
          color: $black;
        }
      }
      &-description {
        @include text('Roboto', normal, 400, 15px, 18px);
        &.twoLine {
          overflow: hidden;
          max-height: 36px;
          & li {
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            white-space: pre-wrap;
          }
        }
      }
    }
    &__handlers {
      margin-top: 15px;
      display: flex;
      align-items: center;
      gap: 20px;
      &-fav {
        cursor: pointer;
      }
      & button,
      &-submit {
        @include text('Noto Sans', normal, 800, 15px, 20px);
        padding: 12px 32px;
        background: $orange;
        color: #fff;
        outline: none;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.17);
        border-radius: 8px;
        border: none;
        cursor: pointer;
        &:disabled {
          background: #ced3d9;
        }
        @media (max-width: 489px) {
          padding: 10px;
          width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content;
        }
      }
      & div {
        display: flex;
        align-items: center;
        gap: 8px;
        @include text('Roboto', normal, 400, 13px, 15px);
        color: $lightBlack;
        & svg {
          cursor: pointer;
          width: 25px;
          height: 25px;
        }
      }
    }
    &__moreInfo {
      padding-top: 20px;
      width: 100%;
      &-up {
        gap: 16px;
        display: flex;
        flex-wrap: wrap;
        &.resume {
          & .card__moreInfo-part {
            width: 100%;
          }
          & .card__moreInfo-list {
            padding-left: 24px;
          }
        }
      }
      &-part {
        width: calc(50% - 8px);
        min-width: 290px;
        & h4 {
          display: flex;
          gap: 8px;
          @include text('Roboto', normal, 500, 15px, 18px);
          color: $orange;
        }
      }
      &-footer {
        margin-top: 18px;
        & h3 {
          @include text('Roboto', normal, 500, 15px, 18px);
          margin-bottom: 10px;
        }
        &-socs {
          display: flex;
          gap: 8px;
          & button {
            background: transparent;
            cursor: pointer;
            outline: none;
            border: none;
            width: 28px;
            height: 28px;
            border-radius: 3px;
            overflow: hidden;
          }
        }
      }
      &-contact {
        margin-top: 8px;
        padding: 12px 16px;
        border: 2px solid rgba(7, 7, 7, 0.4);
        border-radius: 8px;
        @include text('Roboto', normal, 400, 15px, 18px);
        & li {
          margin-bottom: 8px;
          & b {
            font-weight: 500;
          }
        }
        &-showBtn {
          cursor: pointer;
          padding: 10px 12px;
          border-radius: 8px;
          border: 1px solid $black;
          margin-top: 12px;
          width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content;
          @include text('Roboto', normal, 500, 16px, 21px);
          color: $black;
          &:hover {
            border: 1px solid $orange;
            color: $orange;
          }
        }
      }
      &-list {
        margin-top: 8px;
        list-style-type: circle;
        padding-left: 42px;
        & li,
        & p {
          position: relative;
          margin-bottom: 7px;
          @include text('Roboto', normal, 400, 15px, 18px);
        }
        & > p::before {
          content: '';
          top: 50%;
          transform: translateY(-50%);
          position: absolute;
          padding: 2px;
          border-radius: 50%;
          left: -10px;
          background-color: $black;
        }
      }
    }
  }
  
  .card_workAdmin {
    width: 300px;
    height: 130px;
  }
  .wrap_workpageAdmin {
    display: flex;
    flex-wrap: wrap;
    max-width: 1000px;
    margin: 0 auto;
  }
  .admin_vac_topAdmin {
    width: 100%;
  }
  .to_rightAdmin {
    margin-left: 15px;
  }
  .settingsAdmin {
    &__card {
      gap: 16px;
      max-width: 376px;
      padding: 24px;
      background: #ffffff;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      @media (max-width: 931px) {
        margin: 0 auto;
      }
    }
    &__card,
    &__form {
      display: flex;
      flex-direction: column;
    }
    &__avatar {
      position: relative;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      margin: 0 auto;
      @media (max-width: 841px) {
        margin: 0 auto !important;
      }
      & img {
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: center;
        object-position: center;
        border-radius: 50%;
        background: #ffb310;
      }
      &-placeholder {
        @include text('Noto Sans', normal, 500, 40px, 54px);
        width: 100px;
        height: 100px;
        text-align: center;
        padding: 20px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #ffb310;
      }
      &-handlers {
        position: absolute;
        right: calc(-24px * 2 - 10px);
        bottom: 0;
        display: flex;
        gap: 10px;
        height: 24px;
        & svg {
          cursor: pointer;
        }
      }
      & input {
        display: none;
      }
    }
    &__form {
      gap: 12px;
      & h4 {
        margin-top: 16px;
        @include text('Noto Sans', normal, 500, 15px, 20px);
      }
      & input,
      & label {
        @include text('Noto Sans', normal, 400, 14px, 19px);
      }
      & input {
        width: 100%;
        padding: 10px 16px;
        border: 1px solid rgba(7, 7, 7, 0.3);
        border-radius: 8px;
      }
      & input[type='submit'] {
        @include text('Noto Sans', normal, 800, 15px, 20px);
        color: #fff;
        background: #549df1;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.17);
        border-radius: 8px;
        border: none;
        cursor: pointer;
        &:disabled {
          cursor: auto;
          background: #ced3d9;
        }
      }
      & label {
        position: relative;
        color: rgba(7, 7, 7, 0.5);
        & span {
          display: inline-block;
          margin-bottom: 4px;
        }
        & .eye {
          cursor: pointer;
          right: 14px;
          top: 9px;
          color: rgba(7, 7, 7, 0.4);
          position: absolute;
          z-index: 2;
          &.topText {
            top: 32px;
          }
        }
      }
      &-delete {
        margin-top: 10px;
        display: flex;
        align-items: center;
        gap: 8px;
        @include text('Noto Sans', normal, 400, 14px, 19px);
        color: #d71f4b;
        & span {
          cursor: pointer;
        }
      }
      &-logout {
        @include text('Noto Sans', normal, 800, 15px, 20px);
        color: #fff;
        display: block;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        background: #d71f4b;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.17);
        border-radius: 8px;
        border: none;
        cursor: pointer;
        padding: 8px 16px;
        margin: 20px auto 0;
        & span {
          cursor: pointer;
        }
      }
    }
  }
  