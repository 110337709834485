* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
  max-height: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  scroll-behavior: smooth;
}

html,
body {
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
}

*::before,
*::after {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

ul,
li {
  list-style: none;
}

body,
div,
p,
a,
input,
ul,
li {
  margin: 0;
  padding: 0;
}

textarea {
  resize: none;
}
