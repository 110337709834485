.preHeader {
  height: 66px;
  &__post {
    &-popup {
      position: fixed;
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 100;
    }
    &-inner {
      background: #ffffff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      padding: 20px 32px;
      z-index: 150;
      & h4 {
        @include text('Noto Sans', normal, 600, 22px, 27px);
      }
      & p {
        margin: 14px 0;
        @include text('Noto Sans', normal, 400, 16px, 22px);
      }
      & form {
        display: flex;
        flex-direction: column;
        gap: 16px;
        min-width: 260px;
        & input {
          text-align: center;
          border: 1px solid rgba(7, 7, 7, 0.3);
          border-radius: 8px;
          padding: 10px 12px;
          outline: none;
        }
        & button {
          @include text('Noto Sans', normal, 800, 15px, 20px);
          border-radius: 8px;
          padding: 10px 12px;
          border: none;
          background: $darkBlue;
          color: #fff;
          outline: none;
          cursor: pointer;
          &:disabled {
            background: #ced3d9;
            color: #fff;
          }
        }
      }
    }
  }
  &.noreg {
    height: 123px;
  }
  &__fixed {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #fff;
    z-index: 100;
  }
  &__inner {
    position: relative;
    padding: 12px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__logo h1 {
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    & span {
      @include text('Roboto', normal, 500, 22px, 22px);
      color: $orange;
    }
  }
  &__nav {
    display: flex;
    gap: 16px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    & a {
      @include text('Noto Sans', normal, 500, 15px, 20px);
      position: relative;
      color: $black;
      transition: all 0.5s;
      &::before {
        content: '';
        position: absolute;
        height: 2px;
        width: 0;
        bottom: -4px;
        left: 50%;
        transform: translateX(-50%);
        transition: all 0.5s;
        background-color: $orange;
      }
      &.active::before,
      &:hover::before {
        transition: all 0.5s;
        width: 100%;
      }
    }
  }
  &__right {
    display: flex;
    align-items: center;
  }
  &__auth {
    display: flex;
    @media (max-width: 490px) {
      justify-content: center;
      padding: 0 16px 24px;
    }
    &-cart {
      max-width: 420px;
      min-width: 380px;
      cursor: auto;
      z-index: 5;
      position: absolute;
      top: 100%;
      // right: -60px;
      right: 0;
      background: #ffffff;
      box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.18);
      border-radius: 10px;
      padding: 20px 24px;
      &-title {
        @include text('Noto Sans', normal, 500, 16px, 22px);
        color: $darkBlue;
        margin-bottom: 8px;
      }
      &-list {
        max-height: 50vh;
        overflow-y: scroll;
      }
      &-item {
        padding: 16px 0;
        display: flex;
        align-items: center;
        gap: 16px;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: 100%;
          width: 100%;
          background-color: rgba(0, 56, 101, 0.1);
          height: 1px;
        }
        &-logo {
          width: 66px;
          min-width: 66px;
          height: 66px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgba(0, 56, 101, 0.15);
          border-radius: 6px;
        }
        &-text {
          display: flex;
          flex-direction: column;
          padding-right: 10px;
          gap: 6px;
        }
        &-name {
          white-space: nowrap;
          @include text('Noto Sans', normal, 600, 16px, 16px);
          color: $black;
        }
        &-info {
          white-space: nowrap;
          @include text('Noto Sans', normal, 400, 15px, 20px);
          display: flex;
          flex-direction: column;
          gap: 5px 12px;
          & span {
            color: $black;
          }
          & p {
            white-space: break-spaces;
          }
        }
        &-remove {
          position: absolute;
          bottom: 10px;
          right: 10px;
          cursor: pointer;
          &:hover svg {
            fill: $black;
          }
        }
        &-price {
          @include text('Noto Sans', normal, 600, 16px, 22px);
          color: $orange;
        }
      }
      &-result {
        margin: 16px 0 20px;
        & span:first-child {
          @include text('Noto Sans', normal, 600, 18px, 25px);
          color: $darkBlue;
        }
        & span:last-child {
          @include text('Noto Sans', normal, 600, 20px, 27px);
          color: $orange;
        }
      }
      &-btn {
        @include text('Noto Sans', normal, 800, 15px, 20px);
        width: 100%;
        padding: 12px 16px;
        text-align: center;
        background: #003865;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.17);
        border-radius: 8px;
        border: none;
        cursor: pointer;
        color: #fff;
      }
    }
    &-manager-sub {
      padding: 20px 16px !important;
      text-align: center;
      &__title {
        @include text('Noto Sans', normal, 600, 16px, 22px);
        color: $darkBlue;
      }
      &__schedule,
      &__phone,
      &__post {
        @include text('Noto Sans', normal, 400, 13px, 18px);
        white-space: nowrap;
        color: $darkBlue;
      }
      &__phone,
      &__post {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        & a,
        & i {
          color: $darkBlue !important;
          font-weight: 500 !important;
        }
      }
      &__socs {
        margin-top: 18px;
        & ul {
          width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          gap: 14px;
          & li {
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content;
            display: flex;
            gap: 8px;
            & a {
              padding: 0 !important;
              color: $black;
              @include text('Noto Sans', normal, 400, 15px, 20px);
              &:hover {
                color: #003865;
                text-decoration: underline;
              }
            }
            & .whatsapp {
              color: #55cd6c;
            }
            & .telegram {
              color: #2aabee;
            }
            & .mail {
              color: $orange;
            }
          }
        }
      }
    }
    &-profile {
      display: flex;
      gap: 10px;
      align-items: center;
      @include text('Noto Sans', normal, 500, 16px, 22px);
      margin-right: 10px;
      h2 {
        height: 36px;
        position: relative;
        font-size: 15px;
        line-height: 20px;
        color: $orange;
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;
      }
      &-sub {
        padding: 7px 0 0;
        z-index: 5;
        top: 100%;
        position: absolute;
        background: #ffffff;
        box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.25);
        overflow: hidden;
        border-radius: 12px;
        &--entity {
          left: -60px;
        }
        &--applicant {
          left: -20px;
        }
        & h4 {
          padding: 4px 16px;
          font-size: 16px;
          font-weight: 600;
        }
        &:hover {
          display: block;
        }
        & strong {
          cursor: unset;
          & p {
            @include text('Noto Sans', normal, 400, 14px, 19px);
            color: $gray;
            padding: 6px 0 6px 16px !important;
            & span:last-child {
              color: #003865;
              font-weight: 500;
            }
          }
        }
        & a,
        i {
          font-style: normal;
          padding: 6px 22px 6px 17px !important;
          white-space: nowrap;
          @include text('Noto Sans', normal, 400, 14px, 19px);
          & svg {
            margin-right: 8px;
          }
        }
      }
      &-avatar {
        width: 36px;
        height: 36px;
        text-align: center;
        border-radius: 50%;
        background: $orange;
        & svg {
          padding: 6px 0;
        }
        &.ava {
          -o-object-fit: cover;
          object-fit: cover;
          -o-object-position: center;
          object-position: center;
        }
      }
    }
    &-logged {
      display: flex;
      align-items: center;
      & a,
      & i {
        cursor: pointer;
        color: rgba(7, 7, 7, 0.6);
        display: flex;
        align-items: center;
        padding: 7px 9px;
        &:hover {
          background: rgba(0, 56, 101, 0.15);
          border-radius: 4px;
        }
      }
    }
    &-links {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        height: 2px;
        width: 0;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        transition: all 0.5s;
        background-color: $orange;
      }
      &.active::before,
      &:hover::before {
        transition: all 0.5s;
        width: 100%;
        max-width: 24px;
      }
      &--count {
        right: 8px;
        top: 5px;
        position: absolute;
        color: #fff;
        width: 12px;
        height: 12px;
        background-color: $darkBlue;
        border-radius: 50%;
        display: grid;
        place-items: center;
        @include text('Noto Sans', normal, 400, 9px, unset);
      }
    }
    & button {
      display: inline-block;
      margin-right: 8px;
      padding: 8px 16px;
      border-radius: 8px;
      @include text('Noto Sans', normal, 400, 15px, 20px);
      cursor: pointer;
      &:hover {
        opacity: (0.8);
      }
      @media (max-width: 490px) {
        width: calc(50% - 4px);
      }
    }
    &-login {
      border: 1px solid rgba(7, 7, 7, 0.5);
      background: transparent;
    }
    &-register {
      border: none;
      background: $orange;
      color: #fff;
    }
  }
}

.langSwitcher {
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
  & span {
    text-transform: capitalize;
    @include text('Noto Sans', normal, 400, 15px, 20px);
  }
  &:hover {
    background: rgba(0, 56, 101, 0.15);
    border-radius: 4px;
  }
  &:hover &__sub-menu {
    display: block;
  }
  &__sub-menu {
    position: absolute;
    display: none;
    top: 100%;
    left: 50%;
    transform: translate(-50%);
    background: #ffffff;
    box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    z-index: 20;
    & p {
      @include text('Noto Sans', normal, 400, 14px, 19px);
      padding: 12px 22px;
      &:hover {
        border-radius: 12px;
        background: rgba(0, 56, 101, 0.15);
      }
    }
  }
}

.bottomNav {
  z-index: 100;
  position: fixed;
  bottom: 0;
  height: 90px;
  width: 100%;
  background: #04062b;
  &__wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    // gap: 10px;
    padding: 0 100px 30px 12px;
  }
  &__link {
    text-align: center;
    // padding: 10px;
    padding: 8px;
    &-text {
      margin-top: 5px;
      @include text('Noto Sans', normal, 500, 11px, 13px);
      color: #b1b5c4;
    }
  }
}
