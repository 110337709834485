.contentContent {
  position: relative;
  padding: 32px 0;
  // min-height: 2880px;
  &__title {
    @include text('Noto Sans', normal, 600, 20px, 27px);
    color: $black;
    text-align: center;
    margin-bottom: 24px;
  }
  &__wrapper {
    display: flex;
    gap: 27px;
    flex-wrap: nowrap;
    & aside {
      position: -webkit-sticky;
      position: sticky;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      top: 70px;
      left: 0;
      width: 272px;
    }
  }
  &__main {
    margin: 0 auto;
    width: calc(100% - 300px);
    @media (max-width: 860px) {
      width: 100%;
    }
  }
  &__nav {
    display: flex;
    gap: 5px;
    position: absolute;
    bottom: calc(100% - 62px);
    right: 0;
    & div {
      cursor: pointer;
      color: $gray;
      & svg {
        width: 25px;
        height: 30px;
      }
    }
  }
  &__bottom {
    padding-top: 32px;
    &__title {
      width: -webkit-fit-content;
      margin: 0 auto;
      width: -moz-fit-content;
      width: fit-content;
      @include text('Noto Sans', normal, 600, 20px, 27px);
      margin-bottom: 24px;
    }
  }
}
