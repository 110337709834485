.authPopup {
  max-width: 392px;
  padding: 32px;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  &__nav {
    margin-bottom: 16px;
    & ul {
      display: flex;
      gap: 10px;
      & li {
        cursor: pointer;
        @include text('Noto Sans', normal, 400, 18px, 25px);
        color: $gray;
        &.active {
          color: $black;
          text-decoration: underline;
        }
      }
    }
  }
  &__form {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    min-width: 328px;
    gap: 10px;
    @media (max-width: 420px) {
      min-width: 250px;
    }
    &-wrapper {
      gap: 16px;
      display: flex;
      justify-content: space-between;
    }
    &-status {
      width: calc(50% - 8px);
      cursor: pointer;
      width: 100%;
      padding: 20px 15px;
      border: 1px solid rgba(7, 7, 7, 0.3);
      -webkit-filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.17));
      filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.17));
      border-radius: 8px;
      position: relative;
      color: $black;
      & input {
        padding: 0;
        margin: 0;
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0 !important;
        &:checked ~ .authPopup__form-status-check {
          display: block;
        }
      }
      &-check {
        display: none;
        position: absolute;
        top: 6px;
        right: 6px;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        text-align: center;
        background: $orange;
        &-svg {
          right: 50% !important;
          transform: translate(50%, -50%) !important;
          color: #fff !important;
        }
      }
      &-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 6px;
        justify-content: center;
        & p {
          @include text(
            'Noto Sans',
            normal,
            500!important,
            15px!important,
            20px!important
          );
          text-align: center;
          white-space: nowrap;
        }
        &-svg {
          transform: unset !important;
          position: unset !important;
          color: $black !important;
          margin: 0 auto;
        }
      }
    }
    & input {
      width: 100%;
      padding: 12px 16px;
      border: 1px solid rgba(7, 7, 7, 0.3);
      border-radius: 8px;
      @include text('Noto Sans', normal, 400, 14px, 19px);
      &.error {
        border: 1px solid #d71f4b;
      }
    }
    & label {
      position: relative;
      & svg {
        cursor: pointer;
        right: 14px;
        top: 50%;
        transform: translateY(-50%);
        color: rgba(7, 7, 7, 0.4);
        position: absolute;
        z-index: 3;
      }
    }
    & input[type='submit'] {
      font-weight: 800;
      font-size: 15px;
      line-height: 20px;
      color: #ffffff;
      background-color: $orange;
      border: none;
      cursor: pointer;
      &:disabled {
        background: #ced3d9;
        cursor: unset;
      }
    }
    &-err {
      font-family: 'Noto Sans';
      font-size: 14px;
      line-height: 19px;
      color: #d71f4b !important;
    }
    &-required {
      & li {
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: start;
        margin-bottom: 6px;
        @include text('Noto Sans', normal, 400, 14px, 19px);
        & div {
          border-radius: 50%;
          width: 12px;
          min-width: 12px;
          height: 12px;
          background-color: #ced3d9;
          &.active {
            background-color: $orange;
          }
        }
      }
    }
    &-desc {
      @include text(
        'Noto Sans',
        normal,
        400!important,
        15px!important,
        21px!important
      );
      cursor: unset;
      & i {
        font-style: normal;
        color: $orange;
      }
    }
    & p {
      @include text('Noto Sans', normal, 400, 13px, 18px);
      color: #003865;
      cursor: pointer;
    }
  }
}
