.created {
  padding: 24px 32px;
  background: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  max-width: 392px;
  min-width: 328px;
  @media (max-width: 420px) {
    min-width: 250px;
  }
  &__content {
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  &__text {
    & h3 {
      @include text('Noto Sans', normal, 600, 18px, 25px);
      color: $orange;
    }
    & p {
      @include text('Noto Sans', normal, 400, 16px, 120%);
      color: $darkBlue;
    }
  }
  &__btns {
    margin-top: 24px;
    & button {
      @include text('Noto Sans', normal, 800, 15px, 20px);
      border-radius: 8px;
      padding: 12px 16px;
      display: block;
      width: 100%;
      border: none;
      cursor: pointer;
      &:first-child {
        background: #003865;
        color: #fff;
        margin-bottom: 10px;
      }
      &:last-child {
        border: 1px solid #003865;
        background: transparent;
        color: $darkBlue;
      }
    }
  }
}
