.personal {
  &__content {
    width: 100%;
    position: relative;
  }
  &__placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    &--content {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 10px;
      & img {
        max-height: 96px;
      }
      & span {
        @include text('Noto Sans', normal, 600, 20px, 27px);
      }
    }
  }
}
