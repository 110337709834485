.settings {
  &__card {
    gap: 16px;
    max-width: 376px;
    padding: 24px;
    background: #ffffff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    @media (max-width: 931px) {
      margin: 0 auto;
    }
  }
  &__card,
  &__form {
    display: flex;
    flex-direction: column;
  }
  &__avatar {
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    @media (max-width: 841px) {
      margin: 0 auto !important;
    }
    & img {
      width: 100px;
      height: 100px;
      line-height: 100px;
      text-align: center;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: center;
      object-position: center;
      border-radius: 50%;
      background: $orange;
    }
    &-placeholder {
      @include text('Noto Sans', normal, 500, 40px, 54px);
      width: 100px;
      height: 100px;
      text-align: center;
      padding: 20px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: $orange;
    }
    &-handlers {
      position: absolute;
      right: calc(-24px * 2 - 10px);
      bottom: 0;
      display: flex;
      gap: 10px;
      height: 24px;
      & svg {
        cursor: pointer;
      }
    }
    & input {
      display: none;
    }
  }
  &__form {
    gap: 12px;
    & h4 {
      margin-top: 16px;
      @include text('Noto Sans', normal, 500, 15px, 20px);
    }
    & input,
    & label {
      @include text('Noto Sans', normal, 400, 14px, 19px);
    }
    & input {
      width: 100%;
      padding: 10px 16px;
      border: 1px solid rgba(7, 7, 7, 0.3);
      border-radius: 8px;
    }
    & input[type='submit'] {
      @include text('Noto Sans', normal, 800, 15px, 20px);
      color: #fff;
      background: #549df1;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.17);
      border-radius: 8px;
      border: none;
      cursor: pointer;
      &:disabled {
        cursor: auto;
        background: #ced3d9;
      }
    }
    & label {
      position: relative;
      color: rgba(7, 7, 7, 0.5);
      & span {
        display: inline-block;
        margin-bottom: 4px;
      }
      & .eye {
        cursor: pointer;
        right: 14px;
        top: 9px;
        color: rgba(7, 7, 7, 0.4);
        position: absolute;
        z-index: 2;
        &.topText {
          top: 32px;
        }
      }
    }
    &-delete {
      margin-top: 10px;
      display: flex;
      align-items: center;
      gap: 8px;
      @include text('Noto Sans', normal, 400, 14px, 19px);
      color: #d71f4b;
      & span {
        cursor: pointer;
      }
    }
    &-logout {
      @include text('Noto Sans', normal, 800, 15px, 20px);
      color: #fff;
      display: block;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      background: #d71f4b;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.17);
      border-radius: 8px;
      border: none;
      cursor: pointer;
      padding: 8px 16px;
      margin: 20px auto 0;
      & span {
        cursor: pointer;
      }
    }
  }
}
