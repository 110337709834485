.replenish {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 24px 24px 70px;
  max-width: 472px;
  width: 100%;
  &__subtitle {
    @include text('Roboto', normal, 700, 14px, 16px);
    color: $darkBlue;
    margin-bottom: 10px;
    text-align: center;
  }
  &__input {
    width: 100%;
    text-align: center;
    padding: 10px 16px;
    border: 1px solid rgba(0, 56, 101, 0.2);
    border-radius: 6px;
    margin-bottom: 24px;
  }
  &__wrapper {
    display: flex;
    gap: 10px 8px;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  &__service-card {
    position: relative;
    @include text('Noto Sans', normal, 700, 14px, 90%);
    color: #fff;
    max-height: 40px;
    width: calc(33.333% - 8px);
    background: #003865;
    border-radius: 8px;
    padding: 6px;
    text-align: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    & svg {
      height: 100%;
    }
    & .check {
      position: absolute;
      right: 10px;
    }
    &:hover {
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 1);
    }
  }
}
