.regionSwiper {
  &__list {
    display: flex;
    gap: 8px;
    white-space: nowrap;
    &-item {
      padding-right: 8px;
      position: relative;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      cursor: pointer;
      transition: all 0.3s ease;
      &::before {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 2px;
        background: $darkBlue;
        transition: all 0.3s ease;
      }
      &:hover::before,
      &.active::before {
        width: 100%;
      }
      & p {
        @include text('Noto Sans', normal, 400, 15px, 20px);
        color: $lightBlack;
      }
      &.active p,
      &:hover p {
        color: $black;
      }
    }
    &-bottom {
      height: 2px;
      background: rgba(0, 56, 101, 0.1);
      margin-bottom: 16px;
    }
  }
  &__form {
    margin: 16px 0 20px;
    display: flex;
    gap: 8px;
    &-input {
      @include text('Noto Sans', normal, 400, 15px, 20px);
      color: $black;
      width: 230px;
      border: 1px solid rgba(7, 7, 7, 0.3);
      border-radius: 8px;
      & input {
        padding: 8px 16px;
      }
      &.initial {
        max-width: 344px;
        width: 100%;
      }
    }
  }
  &__link-more {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    @include text('Noto Sans', normal, 700, 15px, 20px);
    margin: 24px auto 0;
    display: block;
    border: 1.5px solid $darkBlue;
    text-align: center;
    color: $darkBlue;
    background-color: transparent;
    -webkit-filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.17));
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.17));
    border-radius: 8px;
    outline: none;
    cursor: pointer;
    padding: 10px 40px;
  }
}
