.favourites {
  &__title {
    @include text('Noto Sans', normal, 600, 20px, 27px);
    text-align: center;
    margin: 16px 0;
  }
  &__text {
    @include text('Noto Sans', normal, 400, 15px, 20px);
    text-align: center;
  }
}
