.employerDetail {
  &__header {
    background: #04062b
      url(http://localhost:3000/static/media/searchBg.bce94d524588f49ff8b4.png)
      center/cover no-repeat;
    height: 320px;
    width: 100%;
    border-radius: 16px;
    margin-bottom: 48px;
  }
  &__content {
    position: relative;
    min-height: 60vh;
    max-width: 1008px;
    margin: 48px auto;
  }
  &__info {
    background: #ffffff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    margin-bottom: 26px;
    &-main {
      padding: 0 24px 40px;
      text-align: center;
    }
    &-logo {
      width: 120px;
      border-radius: 50%;
      margin-top: -40px;
      -o-object-fit: cover;
      object-fit: cover;
      height: 120px;
    }
    &-title {
      margin: 16px 0;
      @include text('Noto Sans', normal, 600, 16px, 22px);
    }
    &-description {
      @include text('Noto Sans', normal, 400, 15px, 20px);
    }
    &-bottomBorder {
      border-radius: 0px 0px 16px 16px;
      background: #003865;
      padding: 18px 20px;
      &-inner {
        display: flex;
        justify-content: space-evenly;
        min-height: 20px;
        overflow-x: hidden;
        gap: 12px;
      }
      &-item {
        display: flex;
        align-items: center;
        gap: 8px;
        @include text('Noto Sans', normal, 500, 14px, 19px);
        color: #fff;
        & img {
          border-radius: 50%;
          width: 32px;
          height: 32px;
        }
        & span {
          white-space: nowrap;
        }
      }
    }
    &-wrapper {
      margin-top: 26px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 16px;
    }
    &-card {
      min-width: -webkit-fit-content;
      min-width: -moz-fit-content;
      min-width: fit-content;
      width: calc(50% - 8px);
      & h4 {
        @include text('Noto Sans', normal, 500, 18px, 21px);
        text-align: center;
        margin-bottom: 10px;
      }
      &.contacts {
        & li {
          position: relative;
          list-style-position: inside;
          &::before {
            position: absolute;
            left: -22px;
          }
          &:first-child::before {
            content: url('../../../assets/svg/detailPageOfCompany/address.svg');
          }
          &:nth-child(2)::before {
            content: url('../../../assets/svg/detailPageOfCompany/web.svg');
          }
          &:nth-child(3)::before {
            content: url('../../../assets/svg/detailPageOfCompany/contact.svg');
          }
          &:nth-child(4)::before {
            content: url('../../../assets/svg/detailPageOfCompany/email.svg');
          }
          & p:first-child {
            @include text('Noto Sans', normal, 400, 14px, 19px);
            color: rgba(7, 7, 7, 0.6);
          }
        }
      }
    }
    &-showBtn {
      cursor: pointer;
      margin: 0 auto;
      padding: 8px 10px;
      border: 1px solid #000000;
      -webkit-filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.17));
      filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.17));
      border-radius: 8px;
      &:hover {
        border: 1px solid $orange;
        color: $orange;
      }
    }
    &-cardInner {
      margin-top: 10px;
      padding: 30px;
      background: #ffffff;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 30px 40px;
      &-part {
        width: calc(50% - 60px);
        min-width: 140px;
        & p:last-child,
        a {
          @include text('Noto Sans', normal, 500, 15px, 20px);
          color: #000;
        }
        & a:hover {
          text-decoration: underline;
        }
      }
    }
  }
  &__vacancies {
    & h2 {
      @include text('Noto Sans', normal, 500, 18px, 21px);
      text-align: center;
      margin: 56px auto 20px;
    }
  }
}
