.personal {
  &__wrapper {
    display: flex;
    gap: 24px;
    flex-wrap: nowrap;
  }
  &__comment {
    padding: 4px 6px;
    border-radius: 6px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    @include text('Roboto', normal, 400, 14px, 16px);
    &--pending {
      color: #003865;
      background: rgba(0, 56, 101, 0.08);
    }
    &--rejected {
      color: #d71f4b;
      background: rgba(215, 31, 74, 0.08);
    }
  }
  &__top {
    display: flex;
    height: 60px;
    align-items: center;
    @media (max-width: 931px) {
      display: none;
    }
    & button {
      border: 1.5px solid $darkBlue;
      max-width: 264px;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      width: 100%;
      @include text('Noto Sans', normal, 800, 15px, 18px);
      padding: 10px;
      cursor: pointer;
      background-color: transparent;
      color: $darkBlue;
      border-radius: 8px;
    }
  }
  &__title {
    @include text('Noto Sans', normal, 600, 20px, 27px);
    margin: 10px auto 24px;
    text-align: center;
  }
  &__nav {
    position: -webkit-sticky;
    position: sticky;
    top: 70px;
    width: 100%;
    padding: 10px 0;
    max-width: 264px;
    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 10px;
    &-list {
      & li {
        cursor: pointer;
        padding: 8px 24px;
        &:hover,
        &.active {
          background: rgba(0, 56, 101, 0.15);
        }
        & a {
          @include text('Noto Sans', normal, 600, 15px, 20px);
          color: $black;
          & span {
            @include text('Noto Sans', normal, 500, 14px, 19px);
            display: inline-block;
            width: 20px;
            height: 20px;
            text-align: center;
            background-color: $darkBlue;
            color: #ffffff;
            margin-left: 4px;
            border-radius: 50%;
          }
        }
      }
    }
  }
  @media (max-width: 931px) {
    &__wrapper {
      justify-content: center;
    }
    &__content {
      display: none;
    }
    &__nav {
      box-shadow: unset;
      max-width: unset;
      &-list {
        & li {
          padding: 24px;
          position: relative;
          &::after {
            content: '';
            background: url('../../../assets/svg/chevron-right.svg')
              center/cover no-repeat;
            width: 24px;
            top: 50%;
            transform: translateY(-50%);
            height: 24px;
            position: absolute;
            right: 10px;
          }
        }
      }
    }
  }
}
