.crud {
  display: flex;
  gap: 30px;
  @media (max-width: 841px) {
    flex-direction: column;
    &__popup {
      transform: unset;
      bottom: 126px !important;
      height: 100%;
      z-index: 101 !important;
    }
    &__nav {
      max-width: unset !important;
    }
    &__list {
      flex-direction: row !important;
      &-item {
        width: calc(25% - 6px);
        height: 4px;
        &::before,
        i,
        span {
          display: none !important;
        }
        &.active {
          background: $orange;
        }
        &.hidden {
          background: rgba(0, 56, 101, 0.15);
        }
      }
    }
    &__template {
      justify-content: center;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      margin: 0 auto;
    }
  }
  @media (max-width: 661px) {
    &__template {
      flex-direction: column;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      &-btns {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: 0 auto;
        float: unset !important;
      }
      &-wrapper {
        width: 100%;
        margin: 0 auto;
      }
    }
  }
  &__subtitle {
    @include text('Noto Sans', normal, 600, 16px, 22px);
    display: flex;
    justify-content: space-between;
    margin-bottom: 3px;
    align-items: center;
    max-width: 850px;
    & button {
      border: 1px solid $darkBlue;
      @include text('Noto Sans', normal, 800, 15px, 18px);
      border-radius: 8px;
      color: $darkBlue;
      padding: 10px 12px;
      cursor: pointer;
      background-color: transparent;
    }
  }
  &__desc {
    @include text('Noto Sans', normal, 400, 14px, 19px);
    margin: 3px 0 18px;
  }
  &__table {
    transition: 0.3s;
    width: 100%;
    max-width: 850px;
    margin-top: 14px;
    background-color: #fff;
    border-collapse: collapse;
    border: 1px solid rgba(7, 7, 7, 0.12);
    border-radius: 4px;
    text-align: left;
    & th,
    & td {
      min-width: 144px;
      overflow-x: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    & thead,
    & tbody tr {
      width: 100%;
      display: table;
      table-layout: fixed;
    }
    & thead {
      border-radius: 4px;
      background: rgba(0, 56, 101, 0.15);
      & th {
        min-width: 120px;
        padding: 10px 14px;
        @include text('Noto Sans', normal, 500, 14px, 24px);
      }
    }
    & tbody {
      width: 100%;
      display: block;
      overflow-y: scroll;
      max-height: 60vh;
      & tr {
        border-bottom: 1px solid rgba(7, 7, 7, 0.12);
      }
      & td {
        min-width: 136px;
        @include text('Noto Sans', normal, 400, 14px, 20px);
        padding: 10px 14px;
        &.action {
          @include text('Noto Sans', normal, 500, 14px, 24px);
          cursor: pointer;
          & p {
            text-align: center;
            &:first-child {
              color: $orange;
            }
            &:last-child {
              color: #d71f4b;
            }
            &:hover {
              text-decoration: underline;
            }
          }
        }
        & a {
          white-space: nowrap;
          color: $black;
          text-decoration: underline;
        }
        & input {
          width: 100%;
          padding: 5px 6px;
          border-radius: 8px;
          border: 1px solid rgba(0, 56, 101, 0.15);
        }
      }
    }
  }
  &__main {
    min-height: 80vh;
    width: 100%;
  }
  &__nav {
    max-width: 234px;
    position: -webkit-sticky;
    position: sticky;
    top: 90px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  &__list {
    &.create {
      gap: 56px;
    }
    display: flex;
    flex-direction: column;
    gap: 24px;
    &-item {
      position: relative;
      &:before {
        position: absolute;
        top: 100%;
        left: 15px;
        content: '';
        height: 56px;
        margin-left: 0.5px;
      }
      &:last-child {
        &:before {
          display: none;
        }
      }
      &.active {
        &::before {
          border-left: 2px dashed #003865;
        }
      }
      &.hidden {
        cursor: auto;
        &::before {
          border-left: 2px dashed rgba(0, 56, 101, 0.15);
        }
        & i {
          background: rgba(0, 56, 101, 0.15);
          & svg {
            display: none;
          }
        }
        & span {
          display: none;
        }
      }
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 12px;
      @include text('Noto Sans', normal, 500, 14px, 16px);
      & i {
        display: block;
        width: 32px;
        min-width: 32px;
        height: 32px;
        border-radius: 50%;
        padding: 8px;
        background: $orange;
        & svg {
          width: 100%;
          height: 100%;
        }
      }
      & span {
        color: #003865;
        &.active {
          color: $orange;
        }
      }
    }
  }
  &__handlers {
    display: flex;
    gap: 8px;
    flex-direction: column;
  }
  &__addBtn {
    display: flex;
    gap: 8px;
    align-items: center;
    cursor: pointer;
    &-icon {
      width: 24px;
      height: 24px;
      padding: 2.5px 0 0px 1px;
      text-align: center;
      cursor: pointer;
      border: 1px solid rgba(255, 179, 16, 0.5);
      border-radius: 6px;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      color: $orange;
    }
    & span {
      @include text('Noto Sans', normal, 400, 14px, 19px);
      color: $orange !important;
    }
  }
  &__removeBtn {
    display: flex;
    gap: 8px;
    align-items: center;
    cursor: pointer;
    &-icon {
      width: 24px;
      height: 24px;
      padding: 2.5px 0 0px 1px;
      text-align: center;
      cursor: pointer;
      border: 1px solid rgba(255, 179, 16, 0.5);
      border-radius: 6px;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      color: $orange;
    }
    & span {
      @include text('Noto Sans', normal, 400, 14px, 19px);
      color: $orange !important;
    }
  }
  &__template {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: start;
    gap: 24px;
    @include text('Noto Sans', normal, 400, 14px, 19px);
    & input,
    & textarea {
      @include text('Noto Sans', normal, 400, 14px, 18px);
      width: 100%;
      padding: 10px 14px;
      border: 1px solid rgba(7, 7, 7, 0.2);
      border-radius: 6px;
      outline: none;
      &:focus:valid {
        border-color: #23a734;
      }
      &:focus:invalid {
        border-color: #d71f4b;
      }
    }
    & .quill {
      border-radius: 6px;
      & .ql-toolbar {
        border-radius: 6px 6px 0 0;
      }
      & .ql-container {
        min-height: 70px;
        border-radius: 0 0 6px 6px;
      }
    }
    & .select {
      width: 100%;
      min-width: 104px;
    }
    & .ql-label {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    &-item,
    & label {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
      &.required {
        position: relative;
        &::after {
          content: '*';
          color: rgb(255, 95, 95);
          position: absolute;
          top: 0;
          right: 0;
        }
      }
      &.row {
        &-wrapper {
          gap: 8px;
        }
      }
      &-addMode {
        display: flex;
        gap: 8px;
        align-items: center;
      }
      &-list {
        overflow-x: scroll;
        display: flex;
        width: calc(100% - 32px);
        gap: 8px;
        box-shadow: inset -20px 0 9px -7px rgba(0, 0, 0, 0.3);
        -ms-overflow-style: none;
        scrollbar-width: none;
        border-radius: 6px;
        &::-webkit-scrollbar {
          display: none;
        }
        & div {
          white-space: nowrap;
          width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content;
          display: flex;
          align-items: center;
          height: 40px;
          justify-content: space-between;
          padding: 0 10px 0 16px;
          background-color: rgba(0, 56, 101, 0.35);
          min-height: 40px;
          border-radius: 6px;
          line-height: 14px;
        }
        & svg {
          cursor: pointer;
        }
      }
      & h4 {
        @include text('Noto Sans', normal, 600, 16px, 22px);
        margin-top: 16px;
      }
      & span {
        @include text('Noto Sans', normal, 400, 14px, 19px);
        color: rgba(7, 7, 7, 0.7);
      }
      & button {
        max-width: 240px;
        padding: 10px;
        border-radius: 8px;
        background: $orange;
        border: none;
        @include text('Noto Sans', normal, 500, 15px, 20px);
        cursor: pointer;
        color: #fff;
      }
      &-wrapper {
        display: flex;
        gap: 16px;
        &.selects {
          gap: 8px;
          &__three {
            & .select {
              width: calc(33% - 5px);
            }
          }
          &__two {
            & .select:first-child {
              width: 100%;
              text-align: center;
            }
            & .select:last-child {
              width: 104px;
            }
          }
        }
        & label {
          cursor: pointer;
          position: relative;
          gap: 4px;
          width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content;
          align-items: center;
          flex-direction: row;
          @include text('Noto Sans', normal, 400, 14px, 19px);
          color: rgba(0, 56, 101, 0.8);
          & input {
            width: 18px;
            height: 0;
            opacity: 0;
          }
          & input:checked + i::before {
            background: $orange url('../../../assets/svg/check.svg') center/10px
              no-repeat;
            border: 1px solid $orange;
          }
          & i::before {
            content: '';
            width: 16px;
            height: 16px;
            position: absolute;
            border-radius: 50%;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            border: 1px solid rgba(0, 56, 101, 0.6);
          }
        }
      }
    }
    &-wrapper {
      max-width: 352px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    &-btns {
      margin-top: 18px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      &.nav {
        justify-content: space-around;
        width: 100%;
        flex-direction: row;
        & button {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: transparent;
          color: $black;
        }
      }
      & button {
        max-width: 350px;
        @include text('Noto Sans', normal, 800, 15px, 20px);
        padding: 12px;
        border-radius: 8px;
        border: none;
        color: #fff;
        background: $orange;
        cursor: pointer;
        &:disabled {
          background: #ced3d9;
        }
      }
      &._next {
        justify-content: end;
        flex-direction: row;
        & button {
          max-width: 208px;
          width: 100%;
        }
      }
    }
  }
  &__popup {
    width: 100%;
    max-width: 658px;
    max-height: 506px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 24px 16px;
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    z-index: 10;
    &-title {
      padding-left: 18px;
      @include text('Noto Sans', normal, 600, 18px, 25px);
      margin-bottom: 8px;
    }
    &-wrapper {
      margin-top: 8px;
      display: flex;
      gap: 24px;
      &-col {
        max-height: 376px;
        width: calc(50% - 12px);
        &.sub_list {
          direction: unset;
          & form {
            height: 100%;
          }
          & ul {
            & li {
              direction: ltr;
              margin-bottom: 16px;
              cursor: pointer;
              position: relative;
              align-items: center;
              display: flex;
              gap: 8px;
              & input {
                opacity: 0;
                &:checked + label::before {
                  background-color: #003865;
                }
              }
              & label {
                &::before {
                  content: '';
                  position: absolute;
                  left: 0;
                  border-radius: 50%;
                  width: 16px;
                  height: 16px;
                  border: 1px solid rgba(0, 56, 101, 0.6);
                }
              }
              & span {
                padding: 0;
              }
            }
          }
        }
        & ul {
          padding-left: 16px;
          display: block;
          direction: rtl;
          height: 100%;
          overflow-y: scroll;
          & li {
            direction: ltr;
            text-align: start;
            @include text('Noto Sans', normal, 500, 14px, 19px);
            & span {
              width: -webkit-fit-content;
              width: -moz-fit-content;
              width: fit-content;
              padding: 8px;
              cursor: pointer;
              border-radius: 6px;
              display: block;
              &.choosed {
                background: rgba(0, 56, 101, 0.9);
                color: #fff;
              }
            }
          }
        }
      }
    }
    &-btns {
      display: flex;
      padding: 16px 18px 0;
      gap: 16px;
      & button {
        border: none;
        padding: 10px 20px;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.17);
        border-radius: 8px;
        color: #fff;
        cursor: pointer;
        @include text('Noto Sans', normal, 800, 15px, 20px);
        background: $orange;
        &:disabled {
          background: #ced3d9;
        }
      }
    }
  }
}

.addResume__backBtn {
  margin: 10px 0 22px;
  display: flex;
  align-items: center;
  gap: 10px;
  @include text('Noto Sans', normal, 600, 15px, 20px);
  cursor: pointer;
}
